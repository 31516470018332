<template>
  <div class="present">
    <div class="nav">
      <h2>2023年促进奖（个人）申报页面</h2>
    </div>
    <div class="main">
      <el-steps :active="num" align-center>
        <el-step title="封面" @click.native="num = 0"></el-step>
        <el-step
          title="填写基本资料和推荐意见表格"
          style="line-height: 25px"
          @click.native="fanhui"
        ></el-step>
        <el-step title="请上传word文档" @click.native="fanhui1"></el-step>
        <el-step title="请上传pdf文档" @click.native="num = 3"></el-step>
      </el-steps>
      <div v-if="num == 0">
        <div>
          <!-- <el-button type="primary" @click="dayin">打印</el-button> -->
          <el-button type="primary" @click="baocun">下载并打印</el-button>
          <!-- <div @click="dayin">dayin</div> -->
        </div>
        <div class="fengmian" id="printTest" ref="imageWrapper">
          <div>
            <div>2023年产学研合作促进奖（个人）</div>
            <div>申报材料</div>
          </div>
          <div style="width: 100%; padding: 0 150px; box-sizing: border-box">
            <div>
              <div>申 报 人：</div>
              <div>{{ objs.cover_name }}</div>
            </div>
            <div>
              <div style="margin: 40px 0">申报单位：（加盖公章）</div>
              <div>{{ objs.cover_unit }}</div>
            </div>
            <div>
              <div>申报时间：</div>
              <div>{{ objs.cover_time }}</div>
            </div>
          </div>
        </div>
        <div class="feng_btn">
          <div @click="num = 1" class="clickXia1">下一步</div>
        </div>
      </div>
      <div v-if="num == 1" class="shenMain">
        <div>
          <!-- <el-button type="primary" @click="dayin">打印</el-button> -->
          <el-button type="primary" @click="baocun">下载并打印</el-button>
          <!-- <div @click="dayin">dayin</div> -->
        </div>
        <div class="tabls" v-if="isShow">
          <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
            <table border="1" cellpading="0" cellspace="0">
              <caption>
                申报人基本信息
              </caption>
              <tbody>
                <tr>
                  <td style="width: 100px">姓名</td>
                  <td>{{ objs.user_name }}</td>
                  <td>性别</td>
                  <td>{{ objs.sex }}</td>

                  <td>民族</td>
                  <td>{{ objs.nation }}</td>
                  <td>籍贯</td>
                  <td colspan="2">{{ objs.native_place }}</td>
                  <!-- <td colspan="2" rowspan="4">相片</td> -->
                </tr>
                <tr>
                  <td>出生年月</td>
                  <td colspan="2">{{ objs.birthday }}</td>
                  <td>政治面貌</td>
                  <td colspan="2">{{ objs.politics_name }}</td>
                  <td>最高学历</td>
                  <td colspan="2">{{ objs.education }}</td>
                </tr>
                <tr>
                  <td>职务</td>
                  <td colspan="2">{{ objs.job }}</td>
                  <td>身份证号</td>
                  <td colspan="2">{{ objs.id_car }}</td>
                  <td>申报人手机</td>
                  <td colspan="2">{{ objs.mobile }}</td>
                </tr>
                <tr>
                  <td>专业</td>
                  <td colspan="2">{{ objs.specialty }}</td>
                  <td>专业技术职称</td>
                  <td colspan="2">{{ objs.discipline }}</td>
                  <td>申报人邮箱</td>
                  <td colspan="2">{{ objs.skill }}</td>
                </tr>
                <tr>
                  <td>工作单位</td>
                  <td colspan="5">{{ objs.work_unit }}</td>
                  <td>单位性质</td>
                  <td colspan="2">{{ objs.nature_of_unit }}</td>
                </tr>
                <tr>
                  <td>单位地址</td>
                  <td colspan="5">{{ objs.unit_address }}</td>
                  <td>邮编</td>
                  <td colspan="2">{{ objs.postcode }}</td>
                </tr>
                <tr>
                  <td>联系部门</td>
                  <td colspan="5">{{ objs.department }}</td>
                  <td>联系人</td>
                  <td colspan="2">{{ objs.department_mobile }}</td>
                </tr>
                <tr>
                  <td>电子邮箱</td>
                  <td colspan="5">{{ objs.email }}</td>
                  <td>电话(加区号)</td>
                  <td colspan="2">{{ objs.tel_mobile }}</td>
                </tr>
                <tr>
                  <td>传真</td>
                  <td colspan="5">{{ objs.fax }}</td>
                  <td>手机</td>
                  <td colspan="2">{{ objs.mobile_phone }}</td>
                </tr>
                <tr>
                  <td height="120px">申报人意见</td>
                  <td colspan="8" height="200px">
                    <div class="zunshou">
                      本人遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">申报人签字</td>
                  <td colspan="3">
                    <input type="text" style="width: 200px" />
                  </td>
                  <td colspan="2">日期</td>
                  <td colspan="4">
                    <input type="text" style="width: 130px" />
                  </td>
                </tr>
                <tr>
                  <td height="220px">申报单位意见</td>
                  <td colspan="8" height="320px">
                    <div class="biaoge">
                      <div>
                        <div>
                          本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                        </div>
                      </div>
                      <div>
                        <div>科研管理部门（盖章）</div>
                        <div>单位（盖章）</div>
                      </div>
                      <div class="faren"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">日期</td>
                  <td colspan="6">
                    <input type="text" style="width: 130px" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="clickXia2" @click="num = 0">上一步</div>
            <div class="clickXia1" @click="clickXia">下一步</div>
          </div>
        </div>
        <div v-else>
          <div class="tabls">
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  推荐意见
                </caption>
                <tbody>
                  <tr>
                    <td>推荐单位 / 推荐专家</td>
                    <td colspan="4" height="80px">{{ objs.recommend }}</td>
                    <td>信用代码 / 身份证号</td>
                    <td colspan="3">{{ objs.recommend_credit_code }}</td>
                  </tr>
                  <tr>
                    <td height="80px">通信地址</td>
                    <td colspan="4">{{ objs.mailing_address }}</td>
                    <td>邮编</td>
                    <td colspan="3">{{ objs.recommend_code }}</td>
                  </tr>
                  <tr>
                    <td height="80px">联系人/推荐专家</td>
                    <td colspan="4">{{ objs.recommend_name }}</td>
                    <td>联系电话</td>
                    <td colspan="3">{{ objs.recommend_tel }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电子邮箱</td>
                    <td colspan="4">{{ objs.recommend_email }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.recommend_mobile }}</td>
                  </tr>
                  <tr>
                    <td width="250px">
                      <div>
                        推荐单位介绍/专家介绍(单位、职务职称、研究领域等):
                      </div>
                    </td>
                    <td colspan="8" height="220px">
                      <div>
                        <div style="text-align: left">
                          {{ objs.recommend_zhuanjia }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>推荐意见</td>
                    <td colspan="8" height="120px">
                      <div style="text-align: left">
                        {{ objs.recommend_opinion }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div>推荐专家签字/推荐单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">日期</td>
                    <td colspan="6">{{ objs.recommend_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tabls_bom">
            <div class="clickXia1" @click="clickXia1">上一步</div>
            <div @click="xiayibu1" class="clickXia1">下一步</div>
          </div>
        </div>
      </div>
      <div v-else-if="num == 2">
        <div class="shangchuan" v-if="isShows == 1">
          <div
            style="
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
          >
            <div style="margin-left: 10px">PDF</div>
            <el-button
              v-if="objs.user_data != null"
              @click="dianji(objs.user_data)"
              >预览</el-button
            >
          </div>
          <div class="clickXia1_main">
            <div class="clickXia1" @click="shangyibu">上一步</div>
            <div class="clickXia1" @click="xiaoxiabu">下一步</div>
          </div>
        </div>
      </div>
      <div v-else-if="num == 3">
        <div class="shangchuan1">
          <div class="box">
            <div>申报人基本信息表盖章签字扫描件（必上传）</div>
            <div style="cursor: pointer" @click="kan(objs.accessory1)">
              <div v-if="objs.accessory1 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>推荐意见表盖章签字扫描件（必上传）</div>
            <div style="cursor: pointer" @click="kan(objs.accessory2)">
              <div v-if="objs.accessory2 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申请人学历、职称证明扫描件（必上传）</div>
            <div style="cursor: pointer" @click="kan(objs.accessory3)">
              <div v-if="objs.accessory3 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申请人获知识产权相关证明扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory4)">
              <div v-if="objs.accessory4 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申请人获奖证书扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory5)">
              <div v-if="objs.accessory5 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申请人产学研合作协议、研发合作协议扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory6)">
              <div v-if="objs.accessory6 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>
              申请人产学研合作成果列入政府资助项目、各项计划相关文件（国家级、省级、市级）扫描件
            </div>
            <div style="cursor: pointer" @click="kan(objs.accessory7)">
              <div v-if="objs.accessory7 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申请人获得金融机构、风投等单位相关支持文件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory8)">
              <div v-if="objs.accessory8 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>其他相关证明</div>
            <div style="cursor: pointer" @click="kan(objs.accessory9)">
              <div v-if="objs.accessory9 != null">预览</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="查看"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
    >
      <iframe :src="pdfUrl" width="100%" height="800px"></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Print from "vue-print-nb";
// import Vue from "vue";
// Vue.use(Print);
import { upload_file, innovation_will } from "../axios/api";
import print from "print-js";
import html2canvas from "html2canvas";
export default {
  props: ["objs"],
  data() {
    return {
      centerDialogVisible: false,
      obj: {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "4",
        accessory1: "",
        accessory2: "",
        accessory3: "",
        accessory4: "",
        accessory5: "",
        accessory6: "",
        accessory7: "",
        accessory8: "",
        accessory9: "",
      },
      url: "",
      id: "",
      textarea: "",
      fileList: [],
      normal: [],
      user_name: "",
      num: 0,
      isShow: true,
      objs: {},
      objs: {},
      vHtml: "",
      isShows: 1,
      isShowss: 1,
      user: { name: "", age: "" },
      stu: [
        { name: "张三", age: 17 },
        { name: "里斯", age: 18 },
        { name: "王五", age: 19 },
      ],
      pdfUrl: "",
    };
  },
  created() {
    this.user_chile_details();
  },
  components: {},

  methods: {
    dianji(e) {
      window.open(e, "_blank");
    },
    kan(e) {
      window.open(e, "_blank");
    },
    insert: function () {
      this.stu.push(this.user);
      this.user = {};
    },
    remove: function (index) {
      this.stu.splice(index, 1);
    },

    dayin() {
      console.log("打印");
      printJS({
        printable: "printTest",
        type: "html",
        repeatTableHeader: true,
        scanStyles: true,
        maxWidth: "100%",
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"],
      });
    },
    baocun() {
      this.$downloadPDF.downloadPDF(
        document.querySelector("#printTest"),
        "2023年促进奖（个人）"
      );
    },
    clickXia() {
      this.isShow = !this.isShow;
    },
    clickXia1() {
      this.isShow = !this.isShow;
    },
    fanhui() {
      this.num = 1;
    },
    fanhui1() {
      this.num = 2;
    },
    xiayibu1() {
      this.num = 2;
      this.isShow = !this.isShow;
    },
    xiayibu2() {
      this.num = 3;
    },
    xiayibu3() {
      this.num = 4;
    },

    xiaoxiabu8() {
      this.num = 3;
    },
    xiaoxiabu() {
      this.num = 3;
    },
    shangyibu() {
      this.num = 1;
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.present {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  background-color: #fff;
}
.main {
  width: 100%;
  height: calc(100% - 50px);
}
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
}
.shenMain table {
  width: 1150px;
  margin: 20px 0;
  border-collapse: collapse;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  width: 120px;
  text-align: center;
  font-size: 30px;
}
caption {
  font-size: 28px;
  margin: 40px;
}
textarea {
  background: none;
  outline: none;
  border: 0px;
  width: 920px;
  height: 200px;
  font-size: 20px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 20px;
  width: 90px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 30px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.biaoge {
  width: 98%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 20px auto;
  /* justify-content: space-between; */
}
.biaoge > div:nth-child(1) {
  text-indent: 2em;
  text-align: left;
}
.zunshou {
  height: 100%;
  text-indent: 2em;
  text-align: left;
}
.biaoge > div:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 150px;
  justify-content: space-between;
}
.biaoge > div:nth-child(2) > div {
  width: 350px;
}
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
/* .shangchuan > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.clickXia1_main {
  display: flex;
  width: 100%;

  justify-content: space-around;
  align-items: center;
}
.clickXia1_main1 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.clickXia1_main > div {
  margin-left: 10px;
}
.tables {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
}
#table {
  width: 100%;
  border-collapse: collapse;
}
#table input {
  background: none;
  outline: none;
  border: 0px;
  width: 150px;
}
#table td {
  width: 80px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}
.box > div:nth-child(1) {
  width: 700px;
}
.box > div:nth-child(2) {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.clickXia2 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
  margin-right: 10px;
}
.fengmian {
  width: 1000px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  width: 240px;
  font-size: 30px;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  border: 0px;
}
.fengmian > div:nth-child(2) > div {
  font-size: 30px;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.faren {
  width: 100%;
  margin-top: 150px;
  display: flex;
  justify-content: center;
}
</style>
