<!--创新人物奖2024 复制的 present 文件-->
<template>
  <div class="present">
      <el-steps :active="num" align-center>
        <el-step title="第一部分" @click.native="num = 1"></el-step>
        <el-step
          title="第二部分"
          style="line-height: 25px"
          @click.native="num = 2"
        ></el-step>
        <el-step title="申报附件" @click.native="num = 3"></el-step>
      </el-steps>
      <div class="nav">
        <h2>2024年创新人物奖</h2>
      </div>
      <div class="main">
        <part-one v-if="num == 1" :fromData.sync="fromData" :readOnly="true"></part-one>
        <part-two v-if="num == 2" :fromData.sync="fromData" :readOnly="true"></part-two>
        <div v-else-if="num == 3">
          <!-- <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 20px 0;
            "
          >
            <el-alert
              title="提示：添加完文件后，请点击上传按钮。PDF文件名中请勿带有小数点和特殊符号，文件需控制在30M以下"
              type="success"
              style="width: 70%; font-size: 22px"
            ></el-alert>
          </div> -->
          <div class="shangchuan1">
            <div class="box">
              <div>附件一：申报人基本信息表盖章签字扫描件（必上传）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory1)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory1 == null || obj.accessory1 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile1"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory1', 1)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件二：推荐意见表盖章签字扫描件（必上传）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory2)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory2 == null || obj.accessory2 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile2"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory2', 2)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <!-- 新增 -->
            <div class="box">
              <div>附件三：申报单位营业执照(法人证书）、申报人身份证扫描件（必上传）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory3)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory3 == null || obj.accessory3 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile3"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory3', 3)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件四：申报人学历、职称证明扫描件（必上传）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory4)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory4 == null || obj.accessory4 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile4"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory4', 4)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件五：申报人获知识产权相关证明扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory5)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory5 == null || obj.accessory5 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile5"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory5', 5)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件六：申报人获奖证书扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory6)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory6 == null || obj.accessory6 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile6"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory6', 6)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件七：申报人产学研合作协议、研发合作协议扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory7)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory7 == null || obj.accessory7 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile7"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory7', 7)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>
               附件八：申报人产学研合作成果列入政府资助项目、各项计划相关文件（国家级、省级、市级）扫描件
              </div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory8)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory8 == null || obj.accessory8 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile8"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory8', 8)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件九：申报人获得金融机构、风投等单位相关支持文件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory9)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory9 == null || obj.accessory9 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile9"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory9', 9)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件十：其他相关证明</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory10)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory10 == null || obj.accessory10 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile10"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory10', 10)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="feng_btn">
            <el-button @click="wancheng" type="primary">上传</el-button>
          </div> -->
        </div>
        <div class="zuihou" v-else-if="num == 4">
          <dir>
            <div>
              <h3>本人知悉中国产学研合作促进会科技创新奖申报条件，了解有关要求</h3>
            </div>
            <div>1.遵守国家法律法规、社会道德风尚和科研诚信要求。</div>
            <div>
              2.填写的材料真实有效，不存在弄虚作假、侵占他人成果等行为，本人对其客观性和真实性负责。
            </div>
            <div>
              3.填写的材料符合国家保密法律法规和奖项保密要求，不涉及国家秘密，可用于评审。
            </div>
            <div style="color: red">
              <input
                class="chea"
                name="Fruit"
                type="checkbox"
                v-model="showss"
                value=""
              />本人对以上内容及全部申报附件材料的客观性和真实性负责
            </div>
            <div>
              <el-button @click="wancheng1" type="primary">确认提交</el-button>
              <el-button @click="daochus" type="primary">打包下载</el-button>
            </div>
          </dir>
        </div>
      </div>
    <!-- </div> -->
    <el-dialog
      :show-close="false"
      :visible.sync="centerDialogVisible"
      width="700px"
      center
    >
      <div class="tijiaos">
        <div>提交成功</div>
        <div style="font-weight: bold; font-size: 24px">
          重要揭示: 请严格按照纸质版材料装订要求进行装订
        </div>
        <div>请于2023年9月10日前将纸质申报材料快递至促进会评奖办</div>
        <div>地址: 北京市海淀区学清路18号院8幢中国产学研合作促进会</div>
        <div>电话:(010)68987182</div>
        <div>
          <div @click="tishi1">下载装订要求</div>
          <div @click="centerDialogVisible = false">返回</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Print from "vue-print-nb";
// import Vue from "vue";
// Vue.use(Print);
import partOne from './partOne.vue'
import partTwo from './partTwo.vue'
import html2canvas from "html2canvas";
import mammoth from "mammoth";
import {
  upload_file,
  user_chile_details,
  innovation_will,
  award_find,
  user_file,
  upload_pdf,
  file_download,
  del_file,
} from "../../axios/api";
import print from "print-js";
export default {
  props: {
    objs: {
      type: Object,
      default: true,
    },
  },
  data() {
    return {
      fromData:{
        cooperation_important_case_data:['','','']
      },
      showss: false,
      user_split_id: "",
      centerDialogVisible: false,
      pdfUrl: "",
      obj: {
        centerDialogVisible: false,
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "4",
        accessory1: "",
        accessory2: "",
        accessory3: "",
        accessory4: "",
        accessory5: "",
        accessory6: "",
        accessory7: "",
        accessory8: "",
        accessory9: "",
      },
      url: "",
      is: true,
      iss: true,
      id: "",
      textarea: "",
      fileList: [],
      normal: [],
      user_name: "",
      num: 1,
      isShow: true,
      fengmian: {},
      xinxi: {},
      xinxi1: {},
      vHtml: "",
      isShows: 1,
      isShowss: 1,
      user: { name: "", age: "" },
      stu: [
        { name: "张三", age: 17 },
        { name: "里斯", age: 18 },
        { name: "王五", age: 19 },
      ],
    };
  },
  created() {
    // this.user_chile_details();
  },
  components: {
    partOne,
    partTwo
  },

  methods: {
    tishi1() {
      window.open(`https://note.ciur.org.cn/tishi1.pdf`);
    },
    dels(val, type) {
      let data = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: type,
      };
      del_file(data).then((res) => {
        if (res.data.code == 200) {
          this.obj[val] = null;
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      });
    },
    zouyibu() {
      this.isShow = true;
      this.num = 1;
    },
    zouyibus() {
      this.isShow = false;
      this.num = 1;
    },
    user_chile_details() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      user_chile_details(params).then((res) => {
        let obj = {...res.data.data}
        
        // 主要学习经历
        obj.study_data = obj.study_data ? JSON.parse(obj.study_data)  : [{},{},{},{},{},{}]
        // 主要工作经历
        obj.work_data = obj.work_data ? JSON.parse(obj.work_data) :  [{},{},{},{},{},{}]
        // 主要社会兼职
        obj.part_time_job_data = obj.part_time_job_data ? JSON.parse(obj.part_time_job_data) :  [{},{},{},{},{}]
        obj.cooperative_innovation_project_matter_data = obj.cooperative_innovation_project_matter_data ? JSON.parse(obj.cooperative_innovation_project_matter_data) : [{},{},{},{},{}]
        obj.research_academic_awards_situation_data = obj.research_academic_awards_situation_data ? JSON.parse(obj.research_academic_awards_situation_data) :  [{},{},{},{},{}]
        obj.representative_papers_data = obj.representative_papers_data ? JSON.parse(obj.representative_papers_data) :  [{},{},{},{},{}]
        obj.patent_works_empower_data = obj.patent_works_empower_data ? JSON.parse(obj.patent_works_empower_data) :  [{},{},{},{},{},{},{},{},{},{}]
        obj.standard_development_status_data = obj.standard_development_status_data ? JSON.parse(obj.standard_development_status_data) :  [{},{},{},{},{}]
        obj.development_data = obj.development_data ? JSON.parse(obj.development_data) :  [{},{},{},{},{}]
        let cooperation_important_case_data = obj.cooperation_important_case_data ? JSON.parse(obj.cooperation_important_case_data) : ['','','']
        obj.cooperation_important_case_dataA = cooperation_important_case_data[0]
        obj.cooperation_important_case_dataB = cooperation_important_case_data[1]
        obj.cooperation_important_case_dataC = cooperation_important_case_data[2]
        let applicant_date = obj.applicant_date ? obj.applicant_date.split('-') : []
        obj.ryear = applicant_date?.[0] || ''
        obj.rMonth = applicant_date?.[1] || ''
        obj.rDay = applicant_date?.[2] || ''
        let applicant_department_date = obj.applicant_department_date ? obj.applicant_department_date.split('-') : []
        obj.dyear = applicant_department_date?.[0] || ''
        obj.dMonth = applicant_department_date?.[1] || ''
        obj.dDay = applicant_department_date?.[2] || ''
        obj.discipline_area = obj.discipline_area ? JSON.parse(obj.discipline_area) : []
        obj.nature_of_unit = obj.nature_of_unit ? JSON.parse(obj.nature_of_unit) : []
        this.fromData = {...obj}
       this.user_split_id = res.data.data.id;
        this.id = res.data.data.id;
        // this.fengmian = {
        //   token: sessionStorage.getItem("Token"),
        //   id: res.data.data.id,
        //   type: "5",
        //   cover_unit: res.data.data.cover_unit,
        //   cover_name: res.data.data.cover_name,
        //   cover_time: res.data.data.cover_time,
        // };
        // if (res.data.data.user_data != null) {
        //   this.url = res.data.data.user_data;
        //   this.fileList.push(this.url);
        // }
        // this.xinxi = {
        //   // res.data.data.;
        //   id: res.data.data.id,
        //   type: 1,
        //   user_name: res.data.data.user_name,
        //   sex: res.data.data.sex,
        //   nation: res.data.data.nation,
        //   native_place: res.data.data.native_place,
        //   birthday: res.data.data.birthday,
        //   politics_name: res.data.data.politics_name,
        //   education: res.data.data.education,
        //   job: res.data.data.job,
        //   id_car: res.data.data.id_car,
        //   mobile: res.data.data.mobile,
        //   specialty: res.data.data.specialty,
        //   discipline: res.data.data.discipline,
        //   skill: res.data.data.skill,
        //   work_unit: res.data.data.work_unit,
        //   nature_of_unit: res.data.data.nature_of_unit,
        //   unit_address: res.data.data.unit_address,
        //   postcode: res.data.data.postcode,
        //   department: res.data.data.department,
        //   department_mobile: res.data.data.department_mobile,
        //   email: res.data.data.email,
        //   tel_mobile: res.data.data.tel_mobile,
        //   fax: res.data.data.fax,
        //   mobile_phone: res.data.data.mobile_phone,
        // };
          this.id = res.data.data.id;
        this.obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          // type: "4",
          accessory1: res.data.data.accessory1,
          accessory2: res.data.data.accessory2,
          accessory3: res.data.data.accessory3,
          accessory4: res.data.data.accessory4,
          accessory5: res.data.data.accessory5,
          accessory6: res.data.data.accessory6,
          accessory7: res.data.data.accessory7,
          accessory8: res.data.data.accessory8,
          accessory9: res.data.data.accessory9,
          accessory10:  res.data.data.accessory10,
        };
        // if (res.data.data.user_data != null) {
        //   this.url = res.data.data.user_data;
        //   this.fileList.push(this.url);
        // }
        // this.xinxi1 = {
        //   id: res.data.data.id,
        //   type: 2,
        //   recommend: res.data.data.recommend,
        //   recommend_zhuanjia: res.data.data.recommend_zhuanjia,
        //   recommend_credit_code: res.data.data.recommend_credit_code,
        //   recommend_code: res.data.data.recommend_code,
        //   mailing_address: res.data.data.mailing_address,
        //   recommend_name: res.data.data.recommend_name,
        //   recommend_tel: res.data.data.recommend_tel,
        //   recommend_email: res.data.data.recommend_email,
        //   recommend_mobile: res.data.data.recommend_mobile,
        //   recommend_opinion: res.data.data.recommend_opinion,
        //   recommend_date: res.data.data.recommend_date,
        // };
      });
    },
    kan(e) {
      if (!e) {
        alert("请上传pdf在预览");
        return;
      }
      window.open(e, "_blank");
    },
    daochus() {
      let p = {
        token: sessionStorage.getItem("Token"),
        user_split_id: this.id,
      };
      file_download(p).then((res) => {
        console.log(res);
        let blob = new Blob([res.data], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement("a"); // 创建a标签
        link.setAttribute(
          "download",
          sessionStorage.getItem("user_name") + "创新奖（个人）"
        );
        link.href = url;
        link.click();
        URL.revokeObjectURL(url); // 释放
      });
    },
    ylan() {
      window.open(this.url);
    },
    xiaxiadayin() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);

            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=首页`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiaxia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 1,
              token: sessionStorage.getItem("Token"),
              type_name: "2023年创新奖（个人）申报页面",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                innovation_will(this.fengmian).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.num = 1;
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    insert: function () {
      this.stu.push(this.user);
      this.user = {};
    },
    remove: function (index) {
      this.stu.splice(index, 1);
    },
    uploadFile(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      const extension = item.file.name.split(".")[1] === "PDF";
      const extension2 = item.file.name.split(".")[1] === "pdf";
      if (!extension && !extension2) {
        this.$message.error("上传模板只能是pdf格式!");
        return;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      // form.append("type", 2);
      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          // this.arr.forEach((item, index) => {
          //   if (item.category == 1) {
          //   }
          // });
          this.url = res.data.url;
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile1(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory1 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile2(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      form.append("type", 2);
      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.obj.accessory2 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile3(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory3 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile4(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory4 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile5(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory5 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile6(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory6 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile7(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory7 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile8(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory8 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile9(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory9 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile10(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory10 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handlePictureCardPreview(file) {
      console.log(file);
      // window.open(
      //   `https://view.officeapps.live.com/op/view.aspx?src=${file.src}`
      // );
    },
    handleChange(file, fileList) {
      console.log(file);
      this.fileList = fileList.slice(-1);
      // let that = this;
      // var reader = new FileReader();
      // reader.readAsArrayBuffer(file.raw);
      // reader.onload = function (e) {
      //   const buffer = e.target.result; // 此时是arraybuffer类型
      //   mammoth
      //     .convertToHtml({ arrayBuffer: buffer })
      //     .then((result) => {
      //       console.log(result);
      //       that.vHtml = result.value;
      //     })
      //     .done();
      // };
    },
    baocun() {
      this.is = !this.is;
      console.log(this.is);
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "2023年创新奖（个人）"
        );
        this.is = !this.is;
      }, 1000);
    },
    dayin() {
      console.log("打印");
      printJS({
        printable: "printTest",
        type: "html",
        repeatTableHeader: true,
        scanStyles: true,
        maxWidth: "100%",
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"],
      });
    },
    clickXias() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=基本信息`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 2,
              token: sessionStorage.getItem("Token"),
              type_name: "申报人基本信息",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi.type = 1;
                this.xinxi.token = sessionStorage.getItem("Token");
                innovation_will(this.xinxi).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.isShow = !this.isShow;
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXia1() {
      this.isShow = !this.isShow;
    },
    fanhui() {
      this.num = 1;
    },
    fanhui1() {
      this.num = 2;
    },
    xiayibu1s() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=推荐意见`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu1() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 3,
              token: sessionStorage.getItem("Token"),
              type_name: "推荐意见",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi1.token = sessionStorage.getItem("Token");
                innovation_will(this.xinxi1).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.num = 2;
                    // this.isShow = !this.isShow;
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu2() {
      this.num = 3;
    },
    xiayibu3() {
      this.num = 4;
    },
    wancheng() {
      this.obj.id = this.id;
      this.obj.type = '15'
      innovation_will(this.obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    wancheng1() {
      if (!this.showss) {
        alert("请勾选");
        return;
      }
      this.$confirm("确认所有资料提交给单位管理员").then((_) => {
        console.log(_);
        let obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          type: 6,
        };
        innovation_will(obj)
          .then((res) => {
            if (res.data.code == 200) {
              this.centerDialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((_) => {});
      });
    },
    xiaoxiabu8() {
      this.num = 3;
    },
    xiaoxiabu() {
      let params = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: 3,
        user_data: this.url,
      };
      innovation_will(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    shangyibu() {
      this.num = 1;
    },
    xiazai() {
      let p = {
        id: 3,
      };
      award_find(p).then((res) => {
        console.log(res);
        // window.location.href = res.data.data.file_url;
        window.open(res.data.data.file_url);
      });
    },
  },
  mounted() {
    let obj = {...this.objs}       
    // 主要学习经历
    obj.study_data = obj.study_data ? JSON.parse(obj.study_data)  : [{},{},{},{},{},{}]
    // 主要工作经历
    obj.work_data = obj.work_data ? JSON.parse(obj.work_data) :  [{},{},{},{},{},{}]
    // 主要社会兼职
    obj.part_time_job_data = obj.part_time_job_data ? JSON.parse(obj.part_time_job_data) :  [{},{},{},{},{}]
    obj.cooperative_innovation_project_matter_data = obj.cooperative_innovation_project_matter_data ? JSON.parse(obj.cooperative_innovation_project_matter_data) : [{},{},{},{},{}]
    obj.research_academic_awards_situation_data = obj.research_academic_awards_situation_data ? JSON.parse(obj.research_academic_awards_situation_data) :  [{},{},{},{},{}]
    obj.representative_papers_data = obj.representative_papers_data ? JSON.parse(obj.representative_papers_data) :  [{},{},{},{},{}]
    obj.patent_works_empower_data = obj.patent_works_empower_data ? JSON.parse(obj.patent_works_empower_data) :  [{},{},{},{},{},{},{},{},{},{}]
    obj.standard_development_status_data = obj.standard_development_status_data ? JSON.parse(obj.standard_development_status_data) :  [{},{},{},{},{}]
    obj.development_data = obj.development_data ? JSON.parse(obj.development_data) :  [{},{},{},{},{}]
    let cooperation_important_case_data = obj.cooperation_important_case_data ? JSON.parse(obj.cooperation_important_case_data) : ['','','']
    obj.cooperation_important_case_dataA = cooperation_important_case_data[0]
    obj.cooperation_important_case_dataB = cooperation_important_case_data[1]
    obj.cooperation_important_case_dataC = cooperation_important_case_data[2]
    let applicant_date = obj.applicant_date ? obj.applicant_date.split('-') : []
    obj.ryear = applicant_date?.[0] || ''
    obj.rMonth = applicant_date?.[1] || ''
    obj.rDay = applicant_date?.[2] || ''
    let applicant_department_date = obj.applicant_department_date ? obj.applicant_department_date.split('-') : []
    obj.dyear = applicant_department_date?.[0] || ''
    obj.dMonth = applicant_department_date?.[1] || ''
    obj.dDay = applicant_department_date?.[2] || ''
    obj.discipline_area = obj.discipline_area ? JSON.parse(obj.discipline_area) : []
    obj.nature_of_unit = obj.nature_of_unit ? JSON.parse(obj.nature_of_unit) : []
    this.fromData = {...obj}
    this.id = obj.id;
    this.obj = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        // type: "4",
        accessory1: obj.accessory1,
        accessory2: obj.accessory2,
        accessory3: obj.accessory3,
        accessory4: obj.accessory4,
        accessory5: obj.accessory5,
        accessory6: obj.accessory6,
        accessory7: obj.accessory7,
        accessory8: obj.accessory8,
        accessory9: obj.accessory9,
        accessory10: obj.accessory10,
    };
  }
};
</script>

<style scoped lang="less">
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.present {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;
}
caption {
  font-size: 28px;
  margin: 40px;
}
textarea {
  background: none;
  outline: none;
  border: 0px;
  width: 920px;
  height: 200px;
  font-size: 20px;
}

.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.biaoge {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  /* margin: 20px auto; */
  /* justify-content: space-between; */
}
.biaoge > div:nth-child(1) {
  text-indent: 2em;
  text-align: left;
}
.zunshou {
  height: 100%;
  text-indent: 2em;
  text-align: left;
  box-sizing: border-box;
  padding: 0 10px;
}
.zunshou div {
  margin-top: -50px;
}
.biaoge > div:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 400px;
  justify-content: space-between;
}
.biaoge > div:nth-child(2) > div {
  width: 350px;
}
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
.shangchuan > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clickXia1_main {
  position: fixed;
  bottom: 20px;
  display: flex;
  width: 75%;
  justify-content: space-around;
  align-items: center;
}
.clickXia1_main1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.clickXia1_main1 > div {
  margin-left: 50px;
}
.tables {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
}
#table {
  width: 100%;
  border-collapse: collapse;
}
#table input {
  background: none;
  outline: none;
  border: 0px;
  width: 150px;
}
#table td {
  width: 80px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
}
.box > div:nth-child(1) {
  width: 880px;
}
.box > div:nth-child(2) {
//   width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fengmian {
  width: 1200px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  width: 240px;
  font-size: 22px;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  font-size: 24px;
  border: 0px;
  border-bottom: 1px solid #999;
}

.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.clickXia2 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
  margin-right: 10px;
}
.faren {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.yiqi {
  display: flex;
  height: calc(100vh - 170px);
  /* background-color: pink; */
}
.yiqi > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yiqi > div:nth-child(1) > div {
  width: 180px;
  color: rgba(70, 57, 57, 0.83);
  cursor: pointer;
  font-size: 20px;
  margin: 15px 0;
}
.yiqi > div:nth-child(1) > div i {
  margin-right: 5px;
}
.yiqi > div:nth-child(1) {
  width: 15%;
  background-color: #fff;
  cursor: pointer;
  height: 100%;
}
.yiqi > div:nth-child(3) {
  box-sizing: border-box;
  padding: 20px;
}
.yiqi > div:nth-child(2) {
  overflow-y: auto !important;
  height: 100%;
  flex: 1;
  margin: 0 10px;
  background-color: #fff;
}
.yiqi > div:nth-child(1) .colors {
  color: #409eff;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.chea {
  margin: 0;
}
.zuihou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.zuihou div {
  width: 800px;
  margin: 40px;
}
.tijiaos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tijiaos > div {
  font-size: 20px;
}
.tijiaos > div:nth-child(1) {
  font-size: 70px;
  font-weight: bold;
}
.tijiaos > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tijiaos > div:last-child > div {
  width: 150px;
  border-radius: 10px;
  height: 40px;
  background: #409efe;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
}
.tijiaos > div {
  margin: 10px 0;
}
.biaogesss {
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  padding: 0 10px;
}
.biaoges {
  box-sizing: border-box;
  padding: 0 10px;
  position: absolute;
}
.cnt-title {
  font-size: 30px;
  margin: 80px 5% 80px 5%;
  display: flex;
  align-items: center;
  b {
    cursor: pointer;
  }
  span {
    color: #999;
  }
}
.cnt-warp {
  overflow: hidden;
  width: 90%;
  margin: auto;
  .cnt-item {
    width: 18%;
    margin-right: 2.5%;
    margin-bottom: 2%;
    float: left;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .cnt-item-top {
      width: 100%;
      padding-top: 133.33%;
      height: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .cnt-item-bottom {
      padding: 30px;
      display: flex;
      justify-content: space-around;
    }
  }
}
.test {
    font-size: 12px;
}
</style>
