import { render, staticRenderFns } from "./indexManage.vue?vue&type=template&id=ecb0d55a&scoped=true"
import script from "./indexManage.vue?vue&type=script&lang=js"
export * from "./indexManage.vue?vue&type=script&lang=js"
import style0 from "./indexManage.vue?vue&type=style&index=0&id=ecb0d55a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecb0d55a",
  null
  
)

export default component.exports