<template>
  <div class="present">
      <el-steps :active="num" align-center>
        <el-step title="第一部分" @click.native="num = 1"></el-step>
        <el-step
          title="第二部分"
          style="line-height: 25px"
          @click.native="num = 2"
        ></el-step>
        <el-step title="申报附件" @click.native="num = 3"></el-step>
      </el-steps>
      <div class="main">
        <part-one v-if="num == 1" :fromData.sync="fromData" :readOnly="true"></part-one>
        <part-two v-if="num == 2" :fromData.sync="fromData" :readOnly="true"></part-two>
        <div v-else-if="num == 3">
          <!-- <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 20px 0;
            "
          >
            <el-alert
              title="提示：添加完文件后，请点击上传按钮。PDF文件名中请勿带有小数点和特殊符号，文件需控制在30M以下"
              type="success"
              style="width: 70%; font-size: 22px"
            ></el-alert>
          </div> -->
          <div class="shangchuan1">
            <div class="box">
              <div>
               附件一：申报单位基本信息表、完成单位情况表、完成人情况表盖章签字扫描件（必上传带水印版）
              </div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    style="width: 113px; height: 45px"
                    @click="kan(obj.accessory1)"
                    >{{
                      obj.accessory1 == null || obj.accessory1 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile1"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory1', 1)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div> 附件二：推荐意见表盖章扫描件（必上传带水印版）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory2)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory2 == null || obj.accessory2 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile2"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory2', 2)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>
                 附件三：所有完成单位的营业执照、法人证书、社团登记证扫描件（必上传）
              </div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory3)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory3 == null || obj.accessory3 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile3"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory3', 3)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
  
            <div class="box">
              <div>
                 附件四：申报项目列入政府资助项目、各项计划相关文件（国家级、省级、市级）扫描件
              </div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory4)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory4 == null || obj.accessory4 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile4"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory4', 4)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div> 附件五：申报项目客观评价、鉴定等相关文件扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory5)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory5 == null || obj.accessory5 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile5"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory5', 5)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
             <div class="box">
              <div> 附件六：申报项目涉及的产学研合作协议、研发合作协议扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory6)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory6 == null || obj.accessory6 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile6"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory6', 6)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
               <div class="box">
              <div> 附件七：申报项目应用证明盖章扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory7)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory7 == null || obj.accessory7 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile7"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory7', 7)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
                   
            <div class="box">
              <div> 附件八：申报项目知识产权相关证明扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory8)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory8 == null || obj.accessory8 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile8"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory8', 8)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>

            <div class="box">
              <div> 附件九：申报项目获奖证书扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory9)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory9 == null || obj.accessory9 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile9"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory9', 9)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <!-- 新增 -->
            <div class="box">
              <div> 附件十：申报项目论文首页扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory10)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory10 == null || obj.accessory10 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile10"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory10', 10)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <!-- 新增 -->
            <div class="box">
              <div> 附件十一：申报项目标准制定证明材料</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory11)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory11 == null || obj.accessory11 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile11"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory11', 11)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
            <div class="box">
              <div>附件十二：其他相关证明</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory12)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory12 == null || obj.accessory12 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <!-- <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile12"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory12', 12)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="feng_btn">
            <el-button @click="wancheng" type="primary">上传</el-button>
          </div> -->
        </div>
        <div class="zuihou" v-else-if="num == 4">
          <dir>
            <div>
              <h3>本人知悉中国产学研合作促进会科技创新奖申报条件，了解有关要求</h3>
            </div>
            <div>1.遵守国家法律法规、社会道德风尚和科研诚信要求。</div>
            <div>
              2.填写的材料真实有效，不存在弄虚作假、侵占他人成果等行为，本人对其客观性和真实性负责。
            </div>
            <div>
              3.填写的材料符合国家保密法律法规和奖项保密要求，不涉及国家秘密，可用于评审。
            </div>
            <div style="color: red">
              <input
                class="chea"
                name="Fruit"
                type="checkbox"
                v-model="showss"
                value=""
              />本人对以上内容及全部申报附件材料的客观性和真实性负责
            </div>
            <div>
              <el-button @click="wancheng1" type="primary">确认提交</el-button>
              <el-button @click="daochus" type="primary">打包下载</el-button>
            </div>
          </dir>
        </div>
      </div>
    <el-dialog
      :show-close="false"
      :visible.sync="centerDialogVisible"
      width="700px"
      center
    >
      <div class="tijiaos">
        <div>提交成功</div>
        <div style="font-weight: bold; font-size: 24px">
          重要揭示: 请严格按照纸质版材料装订要求进行装订
        </div>
        <div>请于2023年9月10日前将纸质申报材料快递至促进会评奖办</div>
        <div>地址: 北京市海淀区学清路18号院8幢中国产学研合作促进会</div>
        <div>电话:(010)68987182</div>
        <div>
          <div @click="tishi1">下载装订要求</div>
          <div @click="centerDialogVisible = false">返回</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Print from "vue-print-nb";
// import Vue from "vue";
// Vue.use(Print);
import mammoth from "mammoth";
import html2canvas from "html2canvas";
import partOne from "./partOne.vue";
import partTwo from "./partTwo.vue";
import {
  upload_file,
  user_chile_details,
  innovation_success,
  innovation_select,
  award_find,
  upload_pdf,
  user_file,
  download_file,
  file_download,
  del_file,
} from "../../axios/api";
import print from "print-js";
import Watermark from "../../assets/static/js/watermark";
export default {
  props: {
    objs: {
      type: Object,
      default: true,
    },
  },
  data() {
    return {
      fromData: {},
      num: 1,
      getRenNum: null,
      getDanweiNum: null,
      danweiShow: true,
      renShow: true,
      wanchenggongsi: "3",
      wanchengren: "5",
      showss: false,
      iss: true,
      ise: 1,
      user_split_id: "",
      titleList: [
        {
          title: "设计方案1",
        },
        {
          title: "设计方案2",
        },
        {
          title: "设计方案3",
        },
        {
          title: "设计方案4",
        },
      ],
      centerDialogVisible: false,
      pdfUrl: "",
      shuzu: [],
      obj: {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "7",
        accessory1: "",
        accessory2: "",
        accessory3: "",
        accessory4: "",
        accessory5: "",
        accessory6: "",
        accessory7: "",
        accessory8: "",
        accessory9: "",
        accessory10: "",
      },
      is: 1,
      url: "",
      id: "",
      textarea: "",
      fileList: [],
      fileLists: [],
      normal: [],
      select: [],
      user_name: "",

      xinxi: {},
      xinxi1: {},
      xinxi2: {},
      shuzus: false,
      vHtml: "",
      isShows: 1,
      isShowss: 1,
      danwei: {},
      is: 1,
      user: { name: "", age: "" },
      stu: [
        { name: "张三", age: 17 },
        { name: "里斯", age: 18 },
        { name: "王五", age: 19 },
      ],

      isShow: true,
      accomplish: [
        {
          id: 1,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 2,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 3,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 4,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
        {
          id: 5,
          company: "",
          code: "",
          site: "",
          postcode: "",
          linkman: "",
          phone: "",
          phones: "",
          mail: "",
          case: "",
        },
      ],
      fengmian: {},
      accomplish_people: [
        {
          id: 1,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 2,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 3,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 4,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 5,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 6,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 7,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 8,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 9,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
        {
          id: 10,
          name: "",
          sex: "",
          ranking: "",
          unit: "",
          job: "",
          technical: "",
          identity: "",
          site: "",
          phone: "",
          phones: "",
          mail: "",
          postcode: "",
        },
      ],
    };
  },

  created() {
    // this.user_chile_details();
    this.innovation_select();
  },
  components: {
    partOne,
    partTwo,
  },

  methods: {
    tishi1() {
      window.open(`https://note.ciur.org.cn/tishi1.pdf`);
    },
    getRen(e) {
      this.getRenNum = e - 1;
      this.renShow = false;
    },
    getDanwei(e) {
      this.getDanweiNum = e - 1;
      this.danweiShow = false;
    },
    dels(val, type) {
      let data = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: type,
      };
      del_file(data).then((res) => {
        if (res.data.code == 200) {
          this.obj[val] = null;
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      });
    },
    danweis(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: i,
              token: sessionStorage.getItem("Token"),
              type_name: `第${e - 1}完成单位`,
            };
            user_file(params).then((res) => {
              if (res.data.msg == "成功") {
                // this.is = e == 6 ? 5 : e;
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    danweisee(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=完成单位`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    danweise(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: i,
              token: sessionStorage.getItem("Token"),
              type_name: `完成人${e - 1}`,
            };
            user_file(params).then((res) => {
              if (res.data.msg == "成功") {
                // this.ise = e == 11 ? 10 : e;
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    danweiseee(e, i) {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=完成人`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    checkedOne(id) {
      console.log(id);
      let idIndex = this.shuzu.indexOf(id);
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        this.shuzu.splice(idIndex, 1);
      } else {
        // 选中该checkbox
        console.log(this.shuzu);
        if (this.shuzu == "") {
          this.shuzu = [];
        }
        this.shuzu.push(id);
      }
      this.xinxi.domain = this.shuzu;
      console.log(this.xinxi.domain);
    },
    innovation_select() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      innovation_select(params).then((res) => {
        console.log(res);
        this.select = res.data.data;
      });
    },
    user_chile_details() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      user_chile_details(params).then((res) => {
        let obj = {...res.data.data}
        obj.accomplish_unit_data_1 =  obj.accomplish_unit_data_1 ? JSON.parse(obj.accomplish_unit_data_1)  : {}
        obj.accomplish_unit_data_2 =  obj.accomplish_unit_data_2 ? JSON.parse(obj.accomplish_unit_data_2)  : {}
        obj.accomplish_unit_data_3 =  obj.accomplish_unit_data_3 ? JSON.parse(obj.accomplish_unit_data_3)  : {}
        obj.accomplish_unit_data_4 =  obj.accomplish_unit_data_4 ? JSON.parse(obj.accomplish_unit_data_4)  : {}
        obj.accomplish_unit_data_5 =  obj.accomplish_unit_data_5 ? JSON.parse(obj.accomplish_unit_data_5)  : {}
        obj.accomplish_man_data_1 =  obj.accomplish_man_data_1 ? JSON.parse(obj.accomplish_man_data_1)  : {}
        obj.accomplish_man_data_2 =  obj.accomplish_man_data_2 ? JSON.parse(obj.accomplish_man_data_2)  : {}
        obj.accomplish_man_data_3 =  obj.accomplish_man_data_3 ? JSON.parse(obj.accomplish_man_data_3)  : {}
        obj.accomplish_man_data_4 =  obj.accomplish_man_data_4 ? JSON.parse(obj.accomplish_man_data_4)  : {}
        obj.accomplish_man_data_5 =  obj.accomplish_man_data_5 ? JSON.parse(obj.accomplish_man_data_5)  : {}
        obj.accomplish_man_data_6 =  obj.accomplish_man_data_6 ? JSON.parse(obj.accomplish_man_data_6)  : {}
        obj.accomplish_man_data_7 =  obj.accomplish_man_data_7 ? JSON.parse(obj.accomplish_man_data_7)  : {}
        obj.accomplish_man_data_8 =  obj.accomplish_man_data_8 ? JSON.parse(obj.accomplish_man_data_8)  : {}
        obj.accomplish_man_data_9 =  obj.accomplish_man_data_9 ? JSON.parse(obj.accomplish_man_data_9)  : {}
        obj.accomplish_man_data_10 =  obj.accomplish_man_data_10 ? JSON.parse(obj.accomplish_man_data_10)  : {} 
        let signature_time = obj.signature_time ? obj.signature_time.split('-') : []
        obj.signature_timeY = signature_time?.[0]
        obj.signature_timeM = signature_time?.[1]
        obj.signature_timeD = signature_time?.[2]
        obj.domain =  obj.domain || []
        obj.app_project_three_years_data = obj.app_project_three_years_data ? JSON.parse(obj.app_project_three_years_data)  : [{},{},{},{},{},{},{},{},{},{}]
        obj.app_project_three_years_benefits_data = obj.app_project_three_years_benefits_data ? JSON.parse(obj.app_project_three_years_benefits_data)  : { sales:[{year:'2021'},{year:'2022'},{year:'2023'}]}
        obj.catalogue_intellectual_property_certificates_data = obj.catalogue_intellectual_property_certificates_data ? JSON.parse(obj.catalogue_intellectual_property_certificates_data)  : [{},{},{},{},{},{},{},{},{},{}]
        obj.scientific_unit_through_project_application_data = obj.scientific_unit_through_project_application_data ? JSON.parse(obj.scientific_unit_through_project_application_data)  : [{},{},{},{},{}]
        obj.representative_thesis_application_project_data = obj.representative_thesis_application_project_data ? JSON.parse(obj.representative_thesis_application_project_data)  : [{},{},{},{},{},{},{},{},{},{}]
        obj.development_project_standards_application_data = obj.development_project_standards_application_data ? JSON.parse(obj.development_project_standards_application_data)  : [{},{},{},{},{}]

        this.fromData = obj;
        this.user_split_id = res.data.data.id;
        this.id = res.data.data.id;
        // if (res.data.data.domain != null) {
        //   this.shuzu = res.data.data.domain;
        //   console.log(this.shuzu);
        // } else {
        //   this.shuzus = true;
        // }
        this.obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          accessory1: res.data.data.accessory1,
          accessory2: res.data.data.accessory2,
          accessory3: res.data.data.accessory3,
          accessory4: res.data.data.accessory4,
          accessory5: res.data.data.accessory5,
          accessory6: res.data.data.accessory6,
          accessory7: res.data.data.accessory7,
          accessory8: res.data.data.accessory8,
          accessory9: res.data.data.accessory9,
          accessory10: res.data.data.accessory10,
          accessory11: res.data.data.accessory11,
          accessory12: res.data.data.accessory12,
        };
        // this.fengmian = {
        //   token: sessionStorage.getItem("Token"),
        //   id: res.data.data.id,
        //   type: "8",
        //   cover_unit: res.data.data.cover_unit,
        //   cover_name: res.data.data.cover_name,
        //   cover_time: res.data.data.cover_time,
        //   cover_success: res.data.data.cover_success,
        // };
        // this.xinxi = {
        //   id: res.data.data.id,
        //   type: 1,
        //   project_name: res.data.data.project_name,
        //   domain_rests: res.data.data.domain_rests,
        //   declare_unit: res.data.data.declare_unit,
        //   accomplish_unit: res.data.data.accomplish_unit,
        //   accomplish_man: res.data.data.accomplish_man,
        //   recommend_unit: res.data.data.recommend_unit,
        //   source: res.data.data.source,
        //   fund_number: res.data.data.fund_number,
        //   patent_invention: res.data.data.patent_invention,
        //   authorization: res.data.data.authorization,
        //   project_start: res.data.data.project_start,
        //   project_end: res.data.data.project_end,
        // };
        // this.xinxi2 = {
        //   // res.data.data.;
        //   id: res.data.data.id,
        //   type: 5,
        //   recommend: res.data.data.recommend,
        //   recommend_zhuanjia: res.data.data.recommend_zhuanjia,
        //   recommend_credit_code: res.data.data.recommend_credit_code,
        //   recommend_code: res.data.data.recommend_code,
        //   mailing_address: res.data.data.mailing_address,
        //   recommend_name: res.data.data.recommend_name,
        //   recommend_tel: res.data.data.recommend_tel,
        //   recommend_email: res.data.data.recommend_email,
        //   recommend_mobile: res.data.data.recommend_mobile,
        //   recommend_opinion: res.data.data.recommend_opinion,
        //   recommend_date: res.data.data.recommend_date,
        // };
        // this.xinxi1 = {
        //   id: res.data.data.id,
        //   type: 2,
        //   work_unit: res.data.data.work_unit,
        //   credit_code: res.data.data.credit_code,
        //   user_name: res.data.data.user_name,
        //   job: res.data.data.job,
        //   unit_address: res.data.data.unit_address,
        //   postcode: res.data.data.postcode,
        //   unit_tel: res.data.data.unit_tel,
        //   unit_mobile: res.data.data.unit_mobile,
        //   department: res.data.data.department,
        //   department_mobile: res.data.data.department_mobile,
        //   mobile_phone: res.data.data.mobile_phone,
        //   tel_mobile: res.data.data.tel_mobile,
        //   email: res.data.data.email,
        //   unit_num: res.data.data.unit_num,
        //   unit_num_ratio: res.data.data.unit_num_ratio,
        //   old_year_earning: res.data.data.old_year_earning,
        //   old_year_profit: res.data.data.old_year_profit,
        //   old_year_expenditure: res.data.data.old_year_expenditure,
        //   old_year_ratio: res.data.data.old_year_ratio,
        // };
        // if (res.data.data.user_data != null) {
        //   this.url = res.data.data.user_data;
        //   this.fileList.push(this.url);
        // }
        // if (res.data.data.accomplish != null) {
        //   this.accomplish = res.data.data.accomplish;
        //   this.wanchenggongsi = res.data.data.accomplish.filter(
        //     (e) => e.company
        //   ).length;
        //   console.log(this.wanchenggongsi);
        // } else {
        //   console.log(123);
        //   this.wanchenggongsi = "3";
        //   this.accomplish = [
        //     {
        //       id: 1,
        //       company: "",
        //       code: "",
        //       site: "",
        //       postcode: "",
        //       linkman: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       case: "",
        //     },
        //     {
        //       id: 2,
        //       company: "",
        //       code: "",
        //       site: "",
        //       postcode: "",
        //       linkman: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       case: "",
        //     },
        //     {
        //       id: 3,
        //       company: "",
        //       code: "",
        //       site: "",
        //       postcode: "",
        //       linkman: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       case: "",
        //     },
        //     {
        //       id: 4,
        //       company: "",
        //       code: "",
        //       site: "",
        //       postcode: "",
        //       linkman: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       case: "",
        //     },
        //     {
        //       id: 5,
        //       company: "",
        //       code: "",
        //       site: "",
        //       postcode: "",
        //       linkman: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       case: "",
        //     },
        //   ];
        // }
        // if (res.data.data.accomplish_people != null) {
        //   this.accomplish_people = res.data.data.accomplish_people;
        //   this.wanchengren = res.data.data.accomplish_people.filter(
        //     (e) => e.name
        //   ).length;
        // } else {
        //   console.log(456);
        //   this.wanchengren = "5";
        //   this.accomplish_people = [
        //     {
        //       id: 1,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 2,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 3,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 4,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 5,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 6,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 7,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 8,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 9,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //     {
        //       id: 10,
        //       name: "",
        //       sex: "",
        //       ranking: "",
        //       unit: "",
        //       job: "",
        //       technical: "",
        //       identity: "",
        //       site: "",
        //       phone: "",
        //       phones: "",
        //       mail: "",
        //       postcode: "",
        //     },
        //   ];
        // }
      });
    },
    insert: function () {
      this.stu.push(this.user);
      this.user = {};
    },
    remove: function (index) {
      this.stu.splice(index, 1);
    },
    kan(e) {
      if (!e) {
        alert("请上传pdf在预览");
        return;
      }
      // this.centerDialogVisible = true;
      // this.pdfUrl = e;
      window.open(e, "_blank");
    },
    stepChange(current) {
      this.num = parseInt(current);
    },
    uploadFile(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      const extension = item.file.name.split(".")[1] === "pdf";
      const extension2 = item.file.name.split(".")[1] === "PDF";
      if (!extension && !extension2) {
        this.$message.error("上传模板只能是pdf格式!");
        return;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        dd1a1f00cab048a4bab22df1ec2e5b86;
        return;
      }
      console.log(item.file);
      const form = new FormData();
      form.append("file", item.file);
      //  form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          // this.arr.forEach((item, index) => {
          //   if (item.category == 1) {
          //   }
          // });
          this.url = res.data.url;
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFiles(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      const extension = item.file.name.split(".")[1] === "pdf";
      // const extension2 = item.file.name.split(".")[1] === "doc";
      if (!extension) {
        alert("上传模板只能是 pdf格式!");
        return;
      }

      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      // upload_pdf(form).then((res) => {
      //   console.log(res);
      // });
      // upload_file(form).then((res) => {
      //   console.log(res);
      //   console.log(this.fileList);
      //   if (res.data.code == 200) {
      //     this.$message({
      //       message: "添加成功",
      //       type: "success",
      //     });
      //     // this.arr.forEach((item, index) => {
      //     //   if (item.category == 1) {
      //     //   }
      //     // });
      //     this.url = res.data.url;
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // this.fileList.forEach((item) => {
      //   item.src = res.data.url;
      // });
      // });
    },
    daochus() {
      let p = {
        user_split_id: this.id,
        token: sessionStorage.getItem("Token"),
      };
      file_download(p).then((res) => {
        let blob = new Blob([res.data], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement("a"); // 创建a标签
        link.setAttribute(
          "download",
          sessionStorage.getItem("user_name") + "成果奖"
        );
        link.href = url;
        link.click();
        URL.revokeObjectURL(url); // 释放
      });
    },
    uploadFile1(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      console.log(this.normal);
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      form.append("type", 2);
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory1 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile2(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      form.append("type", 2);
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          console.log(this.obj.accessory1);
          this.obj.accessory2 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile3(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory3 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile4(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory4 = res.data.url;
          console.log(this.obj);
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile5(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory5 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile6(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory6 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile7(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory7 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile8(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory8 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile9(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory9 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile10(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory10 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile11(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory11 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile12(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory12 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    xiaxiasa() {
      this.num = 1;
      this.isShow = true;
    },
    xiaxia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 1,
              token: sessionStorage.getItem("Token"),
              type_name: "2023年创新成果奖申报页面首页",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                innovation_success(this.fengmian).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    handlePictureCardPreview(file) {
      console.log(file);
      // window.open(
      //   `https://view.officeapps.live.com/op/view.aspx?src=${file.src}`
      // );
    },
    handleChange(file, fileList) {
      console.log(file);
      this.fileList = fileList.slice(-1);
    },
    handleChanges(file, fileList) {
      console.log(file);
      this.fileLists = fileLists.slice(-1);
    },
    ylan() {
      window.open(this.url);
    },
    dayin() {
      console.log("打印");
      printJS({
        printable: "printTest",
        type: "html",
        repeatTableHeader: true,
        scanStyles: true,
        maxWidth: "1300px",
        style: ["*"],
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"],
      });
    },
    baocun() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报"
        );

        // setTimeout(() => {
        //   console.log(this.$myfile);
        // }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 2,
              token: sessionStorage.getItem("Token"),
              type_name: "成果基本信息",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi.type = 1;
                this.xinxi.token = sessionStorage.getItem("Token");
                this.xinxi.domain = this.shuzu;
                innovation_success(this.xinxi).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });

                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    fengmianxiazai() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=封面`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXias() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=成果信息`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiazai() {
      let p = {
        id: 7,
      };
      award_find(p).then((res) => {
        console.log(res);
        window.location.href = res.data.data.file_url;
        // window.open(res.data.data.file_url)
      });
    },
    clickXia1s() {
      let pas = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "3",
        accomplish: this.accomplish,
      };
      innovation_success(pas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.user_chile_details();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    click1se() {
      this.num = 1;
      this.isShow = 4;
    },
    clickXia1see() {
      let pas = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "3",
        accomplish: this.accomplish,
      };
      innovation_success(pas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });

          this.user_chile_details();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    clickXia1s1e() {
      this.num = 1;
      this.isShow = 5;
    },
    clickXia1s1() {
      let pas = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: 4,
        accomplish_people: this.accomplish_people,
      };
      innovation_success(pas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.user_chile_details();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    clickXia1() {
      this.isShow = true;
    },
    clickXiasa() {
      this.num = 1;
      this.isShow = 2;
    },
    xiyibuasa() {
      this.num = 1;
      this.isShow = 3;
    },
    clickXia1se() {
      this.num = 1;
      this.isShow = 6;
    },
    xiyibua() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 3,
              token: sessionStorage.getItem("Token"),
              type_name: "申报单位信息",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi1.type = 2;
                this.xinxi1.token = sessionStorage.getItem("Token");
                innovation_success(this.xinxi1).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });

                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiyibuas() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=单位信息`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    fanhui() {
      this.num = 1;
      console.log(this.num);
    },
    fanhui1() {
      this.num = 2;
    },
    xiayibu1sa() {
      this.num = 2;
    },
    xiayibu1() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 19,
              token: sessionStorage.getItem("Token"),
              type_name: "推荐意见",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi2.token = sessionStorage.getItem("Token");
                this.xinxi2.type = 5;
                innovation_success(this.xinxi2).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu1ss() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=推荐意见`
            );
          });
        }, 1000);
        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu2() {
      this.num = 3;
    },
    xiayibu3() {
      this.num = 4;
    },
    wancheng() {
      console.log(this.obj);
      this.obj.id = this.id;
      this.obj.type = '32'
      innovation_success(this.obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    wancheng1() {
      if (!this.showss) {
        alert("请勾选");
        return;
      }
      this.$confirm("确认所有资料提交给单位管理员").then((_) => {
        let obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          type: '101',
        };
        innovation_success(obj)
          .then((res) => {
            if (res.data.code == 200) {
              this.centerDialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((_) => {});
      });
    },
    xiaoxiabu8() {
      this.num = 3;
    },
    xiaoxiabu() {
      let params = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: 6,
        user_data: this.url,
      };
      console.log(params);
      // return;
      innovation_success(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.num = 3;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    shangyibu() {
      this.num = 1;
      this.isShow = 5;
    },
  },
    mounted() {
    let obj = {...this.objs}       
     obj.accomplish_unit_data_1 =  obj.accomplish_unit_data_1 ? JSON.parse(obj.accomplish_unit_data_1)  : {}
    obj.accomplish_unit_data_2 =  obj.accomplish_unit_data_2 ? JSON.parse(obj.accomplish_unit_data_2)  : {}
    obj.accomplish_unit_data_3 =  obj.accomplish_unit_data_3 ? JSON.parse(obj.accomplish_unit_data_3)  : {}
    obj.accomplish_unit_data_4 =  obj.accomplish_unit_data_4 ? JSON.parse(obj.accomplish_unit_data_4)  : {}
    obj.accomplish_unit_data_5 =  obj.accomplish_unit_data_5 ? JSON.parse(obj.accomplish_unit_data_5)  : {}
    obj.accomplish_man_data_1 =  obj.accomplish_man_data_1 ? JSON.parse(obj.accomplish_man_data_1)  : {}
    obj.accomplish_man_data_2 =  obj.accomplish_man_data_2 ? JSON.parse(obj.accomplish_man_data_2)  : {}
    obj.accomplish_man_data_3 =  obj.accomplish_man_data_3 ? JSON.parse(obj.accomplish_man_data_3)  : {}
    obj.accomplish_man_data_4 =  obj.accomplish_man_data_4 ? JSON.parse(obj.accomplish_man_data_4)  : {}
    obj.accomplish_man_data_5 =  obj.accomplish_man_data_5 ? JSON.parse(obj.accomplish_man_data_5)  : {}
    obj.accomplish_man_data_6 =  obj.accomplish_man_data_6 ? JSON.parse(obj.accomplish_man_data_6)  : {}
    obj.accomplish_man_data_7 =  obj.accomplish_man_data_7 ? JSON.parse(obj.accomplish_man_data_7)  : {}
    obj.accomplish_man_data_8 =  obj.accomplish_man_data_8 ? JSON.parse(obj.accomplish_man_data_8)  : {}
    obj.accomplish_man_data_9 =  obj.accomplish_man_data_9 ? JSON.parse(obj.accomplish_man_data_9)  : {}
    obj.accomplish_man_data_10 =  obj.accomplish_man_data_10 ? JSON.parse(obj.accomplish_man_data_10)  : {} 
    let signature_time = obj.signature_time ? obj.signature_time.split('-') : []
    obj.signature_timeY = signature_time?.[0]
    obj.signature_timeM = signature_time?.[1]
    obj.signature_timeD = signature_time?.[2]
    obj.domain =  obj.domain || []
    obj.app_project_three_years_data = obj.app_project_three_years_data ? JSON.parse(obj.app_project_three_years_data)  : [{},{},{},{},{},{},{},{},{},{}]
    obj.app_project_three_years_benefits_data = obj.app_project_three_years_benefits_data ? JSON.parse(obj.app_project_three_years_benefits_data)  : { sales:[{year:'2021'},{year:'2022'},{year:'2023'}]}
    obj.catalogue_intellectual_property_certificates_data = obj.catalogue_intellectual_property_certificates_data ? JSON.parse(obj.catalogue_intellectual_property_certificates_data)  : [{},{},{},{},{},{},{},{},{},{}]
    obj.scientific_unit_through_project_application_data = obj.scientific_unit_through_project_application_data ? JSON.parse(obj.scientific_unit_through_project_application_data)  : [{},{},{},{},{}]
    obj.representative_thesis_application_project_data = obj.representative_thesis_application_project_data ? JSON.parse(obj.representative_thesis_application_project_data)  : [{},{},{},{},{},{},{},{},{},{}]
    obj.development_project_standards_application_data = obj.development_project_standards_application_data ? JSON.parse(obj.development_project_standards_application_data)  : [{},{},{},{},{}]
    this.fromData = {...obj}
    this.id = obj.id;
    this.obj = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        accessory1: obj.accessory1,
        accessory2: obj.accessory2,
        accessory3: obj.accessory3,
        accessory4: obj.accessory4,
        accessory5: obj.accessory5,
        accessory6: obj.accessory6,
        accessory7: obj.accessory7,
        accessory8: obj.accessory8,
        accessory9: obj.accessory9,
        accessory10: obj.accessory10,
        accessory11: obj.accessory11,
        accessory12: obj.accessory12,
    };
  }
};
</script>

<style scoped>
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.present {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: calc(100% - 50px);
}
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  /* width: 120px; */
  text-align: center;
  font-size: 30px;
}
caption {
  font-size: 28px;
  margin: 40px;
}
textarea {
  background: none;
  outline: none;
  border: 0px;
  width: 920px;
  height: 200px;
  font-size: 30px;
}
.tabls input {
  background: none;

  outline: none;
  border: 0px;
  font-size: 30px;
  width: 90px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 30px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shenMain td {
  text-align: left;
}
.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.clickXia2 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
  margin-right: 10px;
}
.biaoge {
  width: 98%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 20px auto;
  position: relative;
  /* justify-content: space-between; */
}
.biaoge > div:nth-child(1) {
  text-indent: 2em;
  text-align: left;
}
.zunshou {
  height: 100%;
  text-indent: 2em;
  text-align: left;
  box-sizing: border-box;
  padding: 0 10px;
}
.zunshou div {
  margin-top: -50px;
}
.biaoge > div:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 260px;
  justify-content: space-between;
}
.biaoge > div:nth-child(2) > div {
  width: 350px;
}
.xuanze input {
  border: 1px solid #999999;
}
.xuanze > div {
  margin: 10px 5px;
}
/* .xuanze {
  width: 100%;
}
.xuanze .input-checkbox {
  width: 30px;
} */
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
.shangchuan > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clickXia1_main {
  position: fixed;
  bottom: 20px;
  display: flex;
  width: 75%;
  justify-content: space-around;
  align-items: center;
  background-color: pink;
}
.clickXia1_main1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.clickXia1_main1 > div {
  margin-left: 50px;
}
.clickXia1_main > div {
  margin-left: 10px;
}
.tables {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
}
#table {
  width: 100%;
  border-collapse: collapse;
}
#table input {
  background: none;
  outline: none;
  border: 0px;
  width: 150px;
}
#table td {
  width: 80px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}
.box > div:nth-child(1) {
  width: 850px;
}
.box > div:nth-child(2) {
  /* width: 400px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aaa {
  display: flex;
}

.tianjia {
  width: 100px !important;
  height: 50px;
  display: flex;
  justify-content: center;

  align-items: center;
  background-color: #409eff;
  cursor: pointer;
  color: #fff;
}
.tianjia1 {
  width: 100px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #409eff;
  cursor: pointer;
  color: #fff;
  margin-right: 20px;
}

.fengmian {
  width: 1200px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  /* padding-bottom: 100px; */
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  flex: 0 0 240px;
  font-size: 22px;
}
.ccc td {
  text-align: center;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  font-size: 30px;
  border: 0px;
  border-bottom: 1px solid #999;
}
.feng_btn > div {
  margin: 10px 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.faren {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.faren > div {
  margin-left: 50px;
}
.yiqi {
  display: flex;
  height: calc(100vh - 170px);
  /* background-color: pink; */
}
.yiqi > div {
  position: relative;
}
.yiqi > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yiqi > div:nth-child(1) > div {
  width: 180px;
  color: rgba(70, 57, 57, 0.83);
  cursor: pointer;
  font-size: 20px;
  margin: 15px 0;
}
.yiqi > div:nth-child(1) > div i {
  margin-right: 5px;
}
.yiqi > div:nth-child(1) {
  width: 15%;
  background-color: #fff;
  cursor: pointer;
  height: 100%;
}
.yiqi > div:nth-child(3) {
  box-sizing: border-box;
  padding: 20px;
}
.yiqi > div:nth-child(2) {
  overflow-y: scroll;
  height: 100%;
  flex: 1;
  margin: 0 10px;
  background-color: #fff;
}
.yiqi > div:nth-child(1) .colors {
  color: #409eff;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.chea {
  margin: 0;
}
.zuihou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.zuihou div {
  width: 800px;
  margin: 40px;
}
.tijiaos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tijiaos > div {
  font-size: 20px;
}
.tijiaos > div:nth-child(1) {
  font-size: 70px;
  font-weight: bold;
}
.tijiaos > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tijiaos > div:last-child > div {
  width: 150px;
  border-radius: 10px;
  height: 40px;
  background: #409efe;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
}
.tijiaos > div {
  margin: 10px 0;
}
.biaoges {
  box-sizing: border-box;
  padding: 0 10px;
  position: absolute;
}
/* .feng_btn > div {
  padding: 10px;
} */
.w-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px;
}
.w-c > div {
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  margin: 40px 0;
}
.w-c > div > div {
  margin-bottom: 20px;
}
.w-cs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
}
.w-cs > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 15px;
}
.w-cs > div > div {
  margin-right: 100px;
}
.a:nth-child(4n) {
  margin-right: 0;
}
</style>
