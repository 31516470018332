<template>
  <div class="present">
    <div class="nav">
      <h2>2023年创新成果奖申报页面</h2>
    </div>
    <div class="main">
      <el-steps :active="num" align-center>
        <el-step title="封面" @click.native="num = 0"></el-step>
        <el-step
          title="填写基本资料和推荐意见表格"
          @click.native="num = 1"
        ></el-step>
        <el-step title="请上传word文档" @click.native="fanhui1"></el-step>
        <el-step title="请上传pdf文档" @click.native="num = 3"></el-step>
      </el-steps>
      <div v-if="num == 0">
        <div>
          <!-- <el-button type="primary" @click="dayin">打印</el-button> -->
          <el-button type="primary" @click="baocun">下载并打印</el-button>
          <!-- <div @click="getPdf('#printTest')">下载并打印</div> -->
          <!-- <div @click="dayin">dayin</div> -->
        </div>
        <div class="fengmian" id="printTest" ref="imageWrapper">
          <div>
            <div>2023年产学研合作创新成果奖</div>
            <div>申报材料</div>
          </div>
          <div style="width: 100%; padding: 0 150px; box-sizing: border-box">
            <div>
              <div style="margin: 40px 0">申报单位:(加盖公章)</div>
              <div>{{ objs.cover_unit }}</div>
            </div>
            <div>
              <div>主要完成单位：</div>
              <div>{{ objs.cover_success }}</div>
            </div>
            <div>
              <div>主要完成人：</div>
              <div>{{ objs.cover_name }}</div>
            </div>
            <div>
              <div>申报时间：</div>
              <div>{{ objs.cover_time }}</div>
            </div>
          </div>
        </div>
        <div class="feng_btn">
          <div class="clickXia2" @click="num = 0">上一步</div>
          <div @click="num = 1" class="clickXia1">下一步</div>
        </div>
      </div>
      <div v-if="num == 1" class="shenMain">
        <div>
          <!-- <el-button type="primary" @click="dayin">打印</el-button> -->
          <el-button type="primary" @click="baocun">保存本地</el-button>
          <!-- <div @click="dayin">dayin</div> -->
        </div>
        <div class="tabls" v-if="isShow == 1">
          <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
            <table border="1" cellpading="0" cellspace="0">
              <caption>
                成果基本信息
              </caption>
              <tbody>
                <tr>
                  <td colspan="2">项目名称</td>
                  <td colspan="3" height="80px">{{ objs.project_name }}</td>
                </tr>

                <tr>
                  <td style="width: 300px" height="80px">项目所属行业领域</td>
                  <td colspan="4">
                    <div v-for="(item, inde) in domain" :key="inde">
                      {{ item.name }}
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                  <td style="width: 300px;">申报单位</td>
                  <td colspan="4" height="80px">{{objs.domain_rests}}</td>
                </tr>-->
                <tr>
                  <td style="width: 300px">申报单位</td>
                  <td colspan="4" height="80px">{{ objs.declare_unit }}</td>
                </tr>
                <tr>
                  <td style="width: 300px">主要完成单位</td>
                  <td colspan="4" height="80px">{{ objs.accomplish_unit }}</td>
                </tr>
                <tr>
                  <td style="width: 300px">主要完成人</td>
                  <td colspan="4" height="80px">{{ objs.accomplish_man }}</td>
                </tr>
                <tr>
                  <td style="width: 300px">推荐单位/推荐专家</td>
                  <td colspan="4" height="80px">{{ objs.recommend_unit }}</td>
                </tr>
                <tr>
                  <td style="width: 300px">任务来源</td>
                  <td colspan="4" height="80px">{{ objs.source }}</td>
                </tr>
                <tr>
                  <td colspan="7" height="200px">
                    <div class="kaozuo">
                      <div>具体计划、基金名称和编号：</div>
                      <div>{{ objs.fund_number }}</div>
                    </div>
                  </td>
                </tr>
                <tr table-layout="fixed">
                  <td width="10%">授权发明专利（项）</td>
                  <td colspan="2">{{ objs.patent_invention }}</td>
                  <td width="10%" style="width: 100px">
                    授权的其他知识产权（项）
                  </td>
                  <td colspan="2">{{ objs.authorization }}</td>
                </tr>
                <tr>
                  <td
                    table-layout="fixed;"
                    width="20%  !important"
                    height="80px"
                  >
                    项目起止时间
                  </td>
                  <td table-layout="fixed;" colspan="2">
                    {{ objs.project_start }}
                  </td>
                  <td table-layout="fixed;" width="20%  !important">
                    完成时间
                  </td>
                  <td table-layout="fixed;" colspan="2">
                    {{ objs.project_end }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="clickXia1" @click="clickXia">下一步</div>
          </div>
        </div>
        <div v-if="isShow == 2">
          <div class="tabls">
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  申报单位信息
                </caption>
                <tbody>
                  <tr>
                    <td width="20%">申报单位名称</td>
                    <td colspan="4" height="80px">{{ objs.work_unit }}</td>
                    <td width="20%">信用代码</td>
                    <td width="30%" colspan="4">{{ objs.credit_code }}</td>
                  </tr>
                  <tr>
                    <td height="80px">单位负责人</td>
                    <td colspan="4">{{ objs.user_name }}</td>
                    <td>职 务</td>
                    <td colspan="4">{{ objs.job }}</td>
                  </tr>
                  <tr>
                    <td height="80px">单位地址</td>
                    <td colspan="4">{{ objs.unit_address }}</td>
                    <td>邮 编</td>
                    <td colspan="3">{{ objs.postcode }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电话（加区号）</td>
                    <td colspan="4">{{ objs.unit_tel }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.unit_mobile }}</td>
                  </tr>
                  <tr>
                    <td height="80px">联系人（联系部门）</td>
                    <td colspan="4">{{ objs.department }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.department_mobile }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电话（加区号）</td>
                    <td colspan="4">{{ objs.tel_mobile }}</td>
                    <td>电子邮箱</td>
                    <td colspan="3">{{ objs.email }}</td>
                  </tr>
                  <tr>
                    <td height="80px">单位职工人数</td>
                    <td colspan="4">{{ objs.unit_num }}</td>
                    <td>大专以上人员 占职工比例</td>
                    <td colspan="3">{{ objs.unit_num_ratio }}</td>
                  </tr>
                  <tr>
                    <td height="80px">上一年总收入</td>
                    <td colspan="4">{{ objs.old_year_earning }}</td>
                    <td>上一年单位总资产</td>
                    <td colspan="3">{{ objs.old_year_profit }}</td>
                  </tr>
                  <tr>
                    <td height="80px">上一年研发经费投入</td>
                    <td colspan="4">{{ objs.old_year_expenditure }}</td>
                    <td>上一年研发经费占单位总成本的比例</td>
                    <td colspan="3">{{ objs.old_year_ratio }}</td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div>科研管理部门（盖章）</div>
                          <div>申报单位（盖章）</div>
                        </div>
                        <div class="faren">
                          <div>法人代表签名：</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">日期</td>
                    <td colspan="6"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tabls_bom">
            <div class="clickXia1" @click="clickXia1">上一步</div>
            <div @click="xiyibua" class="clickXia1">下一步</div>
          </div>
        </div>
        <div v-if="isShow == 3">
          <div class="tabls" v-if="is == 1">
            <div>第一个完成单位</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成单位情况表
                </caption>
                <tbody>
                  <tr>
                    <td width="20%">完成单位名称</td>
                    <td width="30%" height="80px">
                      {{
                        objs.accomplish[0].company
                          ? objs.accomplish[0].company
                          : ""
                      }}
                    </td>
                    <td width="20%">信用代码</td>
                    <td colspan="4">
                      {{
                        objs.accomplish[0].code ? objs.accomplish[0].code : ""
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td width="20%" height="80px">通讯地址</td>
                    <td width="30%">
                      {{
                        objs.accomplish[0].site ? objs.accomplish[0].site : ""
                      }}
                    </td>
                    <td width="20%">邮 编</td>
                    <td colspan="4">
                      {{
                        objs.accomplish[0].postcode
                          ? objs.accomplish[0].postcode
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%" height="80px">联系人</td>

                    <td>{{ objs.accomplish[0].linkman }}</td>
                    <td width="20%">手机</td>
                    <td colspan="4">
                      {{
                        objs.accomplish[0].phone ? objs.accomplish[0].phone : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td height="80px">电话</td>
                    <td>
                      {{
                        objs.accomplish[0].phones
                          ? objs.accomplish[0].phones
                          : ""
                      }}
                    </td>
                    <td>电子邮箱</td>
                    <td colspan="3">
                      {{
                        objs.accomplish[0].mail ? objs.accomplish[0].mail : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="200px">
                      <div class="kaozuo">
                        <div style="margin-bottom: 10px">
                          科技创新、推广应用情况：（限800字）
                        </div>
                        <div style>
                          {{
                            objs.accomplish[0].case
                              ? objs.accomplish[0].case
                              : ""
                          }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div>单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1">日期</td>
                    <td colspan="7"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <!-- <div  class="tianjia1" >返回</div> -->
              <div class="tianjia" @click="is = 2">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="is == 2">
            <div>第二个完成单位</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成单位情况表
                </caption>
                <tbody>
                  <tr>
                    <td width="20%">完成单位名称</td>
                    <td width="30%" height="80px">
                      {{ objs.accomplish[1].company }}
                    </td>
                    <td width="20%">信用代码</td>
                    <td colspan="4">{{ objs.accomplish[1].code }}</td>
                  </tr>

                  <tr>
                    <td height="80px">通讯地址</td>
                    <td>{{ objs.accomplish[1].site }}</td>
                    <td>邮 编</td>
                    <td colspan="3">{{ objs.accomplish[1].postcode }}</td>
                  </tr>
                  <tr>
                    <td height="80px">联系人</td>
                    <td>{{ objs.accomplish[1].linkman }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.accomplish[1].phone }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电话</td>
                    <td>{{ objs.accomplish[1].phones }}</td>
                    <td>电子邮箱</td>
                    <td colspan="3">{{ objs.accomplish[1].mail }}</td>
                  </tr>
                  <tr>
                    <td colspan="8" height="200px">
                      <div class="kaozuo">
                        <div style="margin-bottom: 10px">
                          科技创新、推广应用情况：（限800字）
                        </div>
                        <div style>{{ objs.accomplish[1].case }}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div>单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1">日期</td>
                    <td colspan="7"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="is = 1">返回</div>
              <div class="tianjia" @click="is = 3">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="is == 3">
            <div>第三个完成单位</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成单位情况表
                </caption>
                <tbody>
                  <tr>
                    <td width="20%">完成单位名称</td>
                    <td width="30%" height="80px">
                      {{ objs.accomplish[2].company }}
                    </td>
                    <td width="20%">信用代码</td>
                    <td colspan="4">{{ objs.accomplish[2].code }}</td>
                  </tr>

                  <tr>
                    <td height="80px">通讯地址</td>
                    <td>{{ objs.accomplish[2].site }}</td>
                    <td>邮 编</td>
                    <td colspan="3">{{ objs.accomplish[2].postcode }}</td>
                  </tr>
                  <tr>
                    <td height="80px">联系人</td>
                    <td>{{ objs.accomplish[2].linkman }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.accomplish[2].phone }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电话</td>
                    <td>{{ objs.accomplish[2].phones }}</td>
                    <td>电子邮箱</td>
                    <td colspan="3">{{ objs.accomplish[2].mail }}</td>
                  </tr>
                  <tr>
                    <td colspan="8" height="200px">
                      <div class="kaozuo">
                        <div style="margin-bottom: 10px">
                          科技创新、推广应用情况：（限800字）
                        </div>
                        <div style>{{ objs.accomplish[2].case }}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div>单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1">日期</td>
                    <td colspan="7"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="is = 2">返回</div>
              <div class="tianjia" @click="is = 4">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="is == 4">
            <div>第四个完成单位</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成单位情况表
                </caption>
                <tbody>
                  <tr>
                    <td width="20%">完成单位名称</td>
                    <td width="30%" height="80px">
                      {{ objs.accomplish[3].company }}
                    </td>
                    <td width="20%">信用代码</td>
                    <td colspan="4">{{ objs.accomplish[3].code }}</td>
                  </tr>

                  <tr>
                    <td height="80px">通讯地址</td>
                    <td>{{ objs.accomplish[3].site }}</td>
                    <td>邮 编</td>
                    <td colspan="3">{{ objs.accomplish[3].postcode }}</td>
                  </tr>
                  <tr>
                    <td height="80px">联系人</td>
                    <td>{{ objs.accomplish[3].linkman }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.accomplish[3].phone }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电话</td>
                    <td>{{ objs.accomplish[3].phones }}</td>
                    <td>电子邮箱</td>
                    <td colspan="3">{{ objs.accomplish[3].mail }}</td>
                  </tr>
                  <tr>
                    <td colspan="8" height="200px">
                      <div class="kaozuo">
                        <div style="margin-bottom: 10px">
                          科技创新、推广应用情况：（限800字）
                        </div>
                        <div style>{{ objs.accomplish[3].case }}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div>单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1">日期</td>
                    <td colspan="7"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="is = 3">返回</div>
              <div class="tianjia" @click="is = 5">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="is == 5">
            <div>第五个完成单位</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成单位情况表
                </caption>
                <tbody>
                  <tr>
                    <td width="20%">完成单位名称</td>
                    <td width="30%" height="80px">
                      {{ objs.accomplish[4].company }}
                    </td>
                    <td width="20%">信用代码</td>
                    <td colspan="4">{{ objs.accomplish[4].code }}</td>
                  </tr>

                  <tr>
                    <td height="80px">通讯地址</td>
                    <td>{{ objs.accomplish[4].site }}</td>
                    <td>邮 编</td>
                    <td colspan="3">{{ objs.accomplish[4].postcode }}</td>
                  </tr>
                  <tr>
                    <td height="80px">联系人</td>
                    <td>{{ objs.accomplish[4].linkman }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.accomplish[4].phone }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电话</td>
                    <td>{{ objs.accomplish[4].phones }}</td>
                    <td>电子邮箱</td>
                    <td colspan="3">{{ objs.accomplish[4].mail }}</td>
                  </tr>
                  <tr>
                    <td colspan="8" height="200px">
                      <div class="kaozuo">
                        <div style="margin-bottom: 10px">
                          科技创新、推广应用情况：（限800字）
                        </div>
                        <div style>{{ objs.accomplish[4].case }}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div></div>
                        </div>
                        <div>
                          <div></div>
                          <div>单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1">日期</td>
                    <td colspan="7"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="is = 4">返回</div>
            </div>
          </div>
          <div class="tabls_bom">
            <div class="clickXia1" @click="isShow = 2">上一步</div>
            <div @click="clickXia1s" class="clickXia1">下一步</div>
          </div>
        </div>
        <div v-if="isShow == 4">
          <div class="tabls" v-if="iss == 1">
            <div>完成人1</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      width="10%"
                      height="80px"
                      colspan="1"
                      style="text-align: center"
                    >
                      姓名
                    </td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[0].name
                          ? objs.accomplish_people[0].name
                          : ""
                      }}
                    </td>
                    <td width="10%" colspan="1" style="text-align: center">
                      性别
                    </td>
                    <td width="20%" colspan="2" style="text-align: center">
                      {{
                        objs.accomplish_people[0].sex
                          ? objs.accomplish_people[0].sex
                          : ""
                      }}
                    </td>
                    <td width="10%" colspan="1">排名</td>
                    <td width="20%" colspan="2">
                      {{
                        objs.accomplish_people[0].ranking
                          ? objs.accomplish_people[0].ranking
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td height="80px" colspan="1" style="text-align: center">
                      工作单位
                    </td>
                    <td colspan="8">
                      {{
                        objs.accomplish_people[0].unit
                          ? objs.accomplish_people[0].unit
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">职务</td>
                    <td height="80px" colspan="2">
                      {{
                        objs.accomplish_people[0].job
                          ? objs.accomplish_people[0].job
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">职称</td>
                    <td height="80px" colspan="2">
                      {{
                        objs.accomplish_people[0].technical
                          ? objs.accomplish_people[0].technical
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[0].identity
                          ? objs.accomplish_people[0].identity
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[0].site
                          ? objs.accomplish_people[0].site
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[0].postcode
                          ? objs.accomplish_people[0].postcode
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[0].phone
                          ? objs.accomplish_people[0].phone
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[0].phones
                          ? objs.accomplish_people[0].phones
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[0].mail
                          ? objs.accomplish_people[0].mail
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <!-- <div class="tianjia1" @click="iss=1">返回</div> -->
              <div class="tianjia" @click="iss = 2">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 2">
            <div>完成人2</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td width="10%" colspan="1" style="text-align: center">
                      姓名
                    </td>
                    <td colspan="2">{{ objs.accomplish_people[1].name }}</td>
                    <td width="10%" colspan="1" style="text-align: center">
                      性别
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[1].sex }}
                    </td>
                    <td
                      height="80px"
                      width="10%"
                      colspan="1"
                      style="text-align: center"
                    >
                      排名
                    </td>
                    <td height="80px" width="20%" colspan="2">
                      {{ objs.accomplish_people[1].ranking }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1" style="text-align: center">工作单位</td>
                    <td height="80px" colspan="8">
                      {{ objs.accomplish_people[1].unit }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">职务</td>
                    <td colspan="2">{{ objs.accomplish_people[1].job }}</td>
                    <td style="text-align: center">职称</td>
                    <td height="80px" colspan="2">
                      {{ objs.accomplish_people[1].technical }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[1].identity }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[1].site }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{ objs.accomplish_people[1].postcode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[1].phone }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">{{ objs.accomplish_people[1].phones }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[1].mail }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 1">返回</div>
              <div class="tianjia" @click="iss = 3">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 3">
            <div>完成人3</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      style="width: 100px"
                      height="80px"
                      width="10%"
                      colspan="1"
                    >
                      姓名
                    </td>
                    <td colspan="2">{{ objs.accomplish_people[2].name }}</td>
                    <td style="text-align: center" width="10%" colspan="1">
                      性别
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[2].sex }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      排名
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[2].ranking }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center" height="80px" colspan="1">
                      工作单位
                    </td>
                    <td colspan="8">{{ objs.accomplish_people[2].unit }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">职务</td>
                    <td height="80px" colspan="2">
                      {{ objs.accomplish_people[2].job }}
                    </td>
                    <td style="text-align: center">职称</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[2].technical }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[2].identity }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[2].site }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{ objs.accomplish_people[2].postcode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[2].phone }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">{{ objs.accomplish_people[2].phones }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[2].mail }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 2">返回</div>
              <div class="tianjia" @click="iss = 4">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 4">
            <div>完成人4</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td style="width: 100px" width="10%" colspan="1">姓名</td>
                    <td height="80px" colspan="2">
                      {{ objs.accomplish_people[3].name }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      性别
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[3].sex }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      排名
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[3].ranking }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center" height="80px" colspan="1">
                      工作单位
                    </td>
                    <td colspan="8">{{ objs.accomplish_people[3].unit }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">职务</td>
                    <td height="80px" colspan="2">
                      {{ objs.accomplish_people[3].job }}
                    </td>
                    <td style="text-align: center">职称</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[3].technical }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[3].identity }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[3].site }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{ objs.accomplish_people[3].postcode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[3].phone }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">{{ objs.accomplish_people[3].phones }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[3].mail }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 3">返回</div>
              <div class="tianjia" @click="iss = 5">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 5">
            <div>完成人5</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      style="width: 100px"
                      height="80px"
                      width="10%"
                      colspan="1"
                    >
                      姓名
                    </td>
                    <td colspan="2">{{ objs.accomplish_people[4].name }}</td>
                    <td style="text-align: center" width="10%" colspan="1">
                      性别
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[4].sex }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      排名
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[4].ranking }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center" height="80px" colspan="1">
                      工作单位
                    </td>
                    <td colspan="8">{{ objs.accomplish_people[4].unit }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">职务</td>
                    <td height="80px" colspan="2">
                      {{ objs.accomplish_people[4].job }}
                    </td>
                    <td style="text-align: center">职称</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[4].technical }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[4].identity }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[4].site }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{ objs.accomplish_people[4].postcode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[4].phone }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">{{ objs.accomplish_people[4].phones }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[4].mail }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 4">返回</div>
              <div class="tianjia" @click="iss = 6">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 6">
            <div>完成人6</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      style="width: 100px"
                      height="80px"
                      width="10%"
                      colspan="1"
                    >
                      姓名
                    </td>
                    <td colspan="2">{{ objs.accomplish_people[5].name }}</td>
                    <td style="text-align: center" width="10%" colspan="1">
                      性别
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[5].sex }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      排名
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[5].ranking }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center" height="80px" colspan="1">
                      工作单位
                    </td>
                    <td colspan="8">{{ objs.accomplish_people[5].unit }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">职务</td>
                    <td height="80px" colspan="2">
                      {{ objs.accomplish_people[5].job }}
                    </td>
                    <td style="text-align: center">职称</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[5].technical }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[5].identity }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[5].site }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{ objs.accomplish_people[5].postcode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[5].phone }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">{{ objs.accomplish_people[5].phones }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[5].mail }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 5">返回</div>
              <div class="tianjia" @click="iss = 7">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 7">
            <div>完成人7</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      style="width: 100px"
                      height="80px"
                      width="10%"
                      colspan="1"
                    >
                      姓名
                    </td>
                    <td colspan="2">{{ objs.accomplish_people[6].name }}</td>
                    <td style="text-align: center" width="10%" colspan="1">
                      性别
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[6].sex }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      排名
                    </td>
                    <td width="20%" colspan="2">
                      {{ objs.accomplish_people[6].ranking }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center" height="80px" colspan="1">
                      工作单位
                    </td>
                    <td colspan="8">{{ objs.accomplish_people[6].unit }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center" height="80px">职务</td>
                    <td colspan="2">{{ objs.accomplish_people[6].job }}</td>
                    <td style="text-align: center">职称</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[6].technical }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{ objs.accomplish_people[6].identity }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[6].site }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{ objs.accomplish_people[6].postcode }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[6].phone }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">{{ objs.accomplish_people[6].phones }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{ objs.accomplish_people[6].mail }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 6">返回</div>
              <div class="tianjia" @click="iss = 8">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 8">
            <div>完成人8</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      style="width: 100px"
                      height="80px"
                      width="10%"
                      colspan="1"
                    >
                      姓名
                    </td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[7].name
                          ? objs.accomplish_people[7].name
                          : ""
                      }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      性别
                    </td>
                    <td width="20%" colspan="2">
                      {{
                        objs.accomplish_people[7].sex
                          ? objs.accomplish_people[7].sex
                          : ""
                      }}
                    </td>
                    <td style="text-align: center" width="10%" colspan="1">
                      排名
                    </td>
                    <td width="20%" colspan="2">
                      {{
                        objs.accomplish_people[7].ranking
                          ? objs.accomplish_people[7].ranking
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center" height="80px" colspan="1">
                      工作单位
                    </td>
                    <td colspan="8">
                      {{
                        objs.accomplish_people[7].unit
                          ? objs.accomplish_people[7].unit
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">职务</td>
                    <td height="80px" colspan="2">
                      {{
                        objs.accomplish_people[7].job
                          ? objs.accomplish_people[7].job
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">职称</td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[7].technical
                          ? objs.accomplish_people[7].technical
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">身份证</td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[7].identity
                          ? objs.accomplish_people[7].identity
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">通信地址</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[7].site
                          ? objs.accomplish_people[7].site
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">邮编</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[7].postcode
                          ? objs.accomplish_people[7].postcode
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电 话</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[7].phone
                          ? objs.accomplish_people[7].phone
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">手机</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[7].phones
                          ? objs.accomplish_people[7].phones
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center">电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[7].mail
                          ? objs.accomplish_people[7].mail
                          : ""
                      }}
                    </td>
                    <td style="text-align: center">签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 7">返回</div>
              <div class="tianjia" @click="iss = 9">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 9">
            <div>完成人9</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      style="width: 100px"
                      height="80px"
                      width="10%"
                      colspan="1"
                    >
                      姓名
                    </td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[8].name
                          ? objs.accomplish_people[8].name
                          : ""
                      }}
                    </td>
                    <td width="10%" colspan="1">性别</td>
                    <td width="20%" colspan="2">
                      {{
                        objs.accomplish_people[8].sex
                          ? objs.accomplish_people[8].sex
                          : ""
                      }}
                    </td>
                    <td width="10%" colspan="1">排名</td>
                    <td width="20%" colspan="2">
                      {{
                        objs.accomplish_people[8].ranking
                          ? objs.accomplish_people[8].ranking
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td height="80px" colspan="1">工作单位</td>
                    <td colspan="8">
                      {{
                        objs.accomplish_people[8].unit
                          ? objs.accomplish_people[8].unit
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>职务</td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[8].job
                          ? objs.accomplish_people[8].job
                          : ""
                      }}
                    </td>
                    <td>职称</td>
                    <td height="80px" colspan="2">
                      {{
                        objs.accomplish_people[8].technical
                          ? objs.accomplish_people[8].technical
                          : ""
                      }}
                    </td>
                    <td>身份证</td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[8].identity
                          ? objs.accomplish_people[8].identity
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>通信地址</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[8].site
                          ? objs.accomplish_people[8].site
                          : ""
                      }}
                    </td>
                    <td>邮编</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[8].postcode
                          ? objs.accomplish_people[8].postcode
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>电 话</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[8].phone
                          ? objs.accomplish_people[8].phone
                          : ""
                      }}
                    </td>
                    <td>手机</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[8].phones
                          ? objs.accomplish_people[8].phones
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[8].mail
                          ? objs.accomplish_people[8].mail
                          : ""
                      }}
                    </td>
                    <td>签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 8">返回</div>
              <div class="tianjia" @click="iss = 10">下一个</div>
            </div>
          </div>
          <div class="tabls" v-if="iss == 10">
            <div>完成人10</div>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  完成人情况表
                </caption>
                <tbody>
                  <tr>
                    <td
                      height="80px"
                      width="10%"
                      colspan="1"
                      style="width: 100px"
                    >
                      姓名
                    </td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[9].name
                          ? objs.accomplish_people[9].name
                          : ""
                      }}
                    </td>
                    <td width="10%" colspan="1">性别</td>
                    <td width="20%" colspan="2">
                      {{
                        objs.accomplish_people[9].sex
                          ? objs.accomplish_people[9].sex
                          : ""
                      }}
                    </td>
                    <td width="10%" colspan="1">排名</td>
                    <td width="20%" colspan="2">
                      {{
                        objs.accomplish_people[9].ranking
                          ? objs.accomplish_people[9].ranking
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td height="80px" colspan="1">工作单位</td>
                    <td colspan="8">
                      {{
                        objs.accomplish_people[9].unit
                          ? objs.accomplish_people[9].unit
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>职务</td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[9].job
                          ? objs.accomplish_people[9].job
                          : ""
                      }}
                    </td>
                    <td>职称</td>
                    <td height="80px" colspan="2">
                      {{
                        objs.accomplish_people[9].technical
                          ? objs.accomplish_people[9].technical
                          : ""
                      }}
                    </td>
                    <td>身份证</td>
                    <td colspan="2">
                      {{
                        objs.accomplish_people[9].identity
                          ? objs.accomplish_people[9].identity
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>通信地址</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[9].site
                          ? objs.accomplish_people[9].site
                          : ""
                      }}
                    </td>
                    <td>邮编</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[9].postcode
                          ? objs.accomplish_people[9].postcode
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>电 话</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[9].phone
                          ? objs.accomplish_people[9].phone
                          : ""
                      }}
                    </td>
                    <td>手机</td>
                    <td colspan="3">
                      {{
                        objs.accomplish_people[9].phones
                          ? objs.accomplish_people[9].phones
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>电子邮箱</td>
                    <td height="80px" colspan="3">
                      {{
                        objs.accomplish_people[9].mail
                          ? objs.accomplish_people[9].mail
                          : ""
                      }}
                    </td>
                    <td>签字</td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="tianjia1" @click="iss = 9">返回</div>
              <!-- <div class="tianjia" @click="iss=10">添加成员</div> -->
            </div>
          </div>
          <div class="tabls_bom">
            <div class="clickXia1" @click="shangshangbu">上一步</div>
            <div @click="clickXia1s1" class="clickXia1">下一步</div>
          </div>
        </div>
        <div v-if="isShow == 5">
          <div class="tabls">
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <table border="1" cellpading="0" cellspace="0">
                <caption>
                  推荐意见
                </caption>
                <tbody>
                  <tr>
                    <td>推荐单位 / 推荐专家</td>
                    <td colspan="4" height="80px">{{ objs.recommend }}</td>
                    <td>信用代码 / 身份证号</td>
                    <td colspan="3">{{ objs.recommend_credit_code }}</td>
                  </tr>
                  <tr>
                    <td height="80px">通信地址</td>
                    <td colspan="4">{{ objs.mailing_address }}</td>
                    <td>邮编</td>
                    <td colspan="3">{{ objs.recommend_code }}</td>
                  </tr>
                  <tr>
                    <td height="80px">联系人/推荐专家</td>
                    <td colspan="4">{{ objs.recommend_name }}</td>
                    <td>联系电话</td>
                    <td colspan="3">{{ objs.recommend_tel }}</td>
                  </tr>
                  <tr>
                    <td height="80px">电子邮箱</td>
                    <td colspan="4">{{ objs.recommend_email }}</td>
                    <td>手机</td>
                    <td colspan="3">{{ objs.recommend_mobile }}</td>
                  </tr>
                  <tr>
                    <td width="250px">
                      <div>
                        推荐单位介绍/专家介绍(单位、职务职称、研究领域等):
                      </div>
                    </td>
                    <td colspan="8" height="220px">
                      <div>
                        <div style="text-align: left">
                          {{ objs.recommend_zhuanjia }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>推荐意见</td>
                    <td colspan="8" height="120px">
                      <div style="text-align: left">
                        {{ objs.recommend_opinion }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div>
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div></div>
                          <div>推荐专家签字/推荐单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">日期</td>
                    <td colspan="6">{{ objs.recommend_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tabls_bom">
            <div class="clickXia1" @click="shangshangbubu">上一步</div>
            <div @click="xiayibu1" class="clickXia1">下一步</div>
          </div>
        </div>
      </div>
      <div v-else-if="num == 2">
        <div class="shangchuan" v-if="isShows == 1">
          <div
            style="
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
          >
            <div style="margin-left: 10px">PDF</div>
            <el-button
              v-if="objs.user_data != null"
              @click="dianji(objs.user_data)"
              >预览</el-button
            >
          </div>
          <div class="clickXia1_main">
            <div class="clickXia1" @click="shangyibu">上一步</div>
            <div class="clickXia1" @click="xiaoxiabu">下一步</div>
          </div>
        </div>
      </div>
      <div v-else-if="num == 3">
        <div class="shangchuan1">
          <div class="box">
            <div>
              申报单位基本信息表、完成单位情况表、完成人情况表盖章签字扫描件（必上传）
            </div>
            <div style="cursor: pointer" @click="kan(objs.accessory1)">
              <div v-if="objs.accessory1 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>推荐意见表盖章扫描件（必上传）</div>
            <div style="cursor: pointer" @click="kan(objs.accessory2)">
              <div v-if="objs.accessory2 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>完成单位营业执照、法人证书、社团登记证扫描件（必上传）</div>
            <div style="cursor: pointer" @click="kan(objs.accessory3)">
              <div v-if="objs.accessory3 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申报项目知识产权相关证明扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory4)">
              <div v-if="objs.accessory4 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申报项目获奖证书扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory5)">
              <div v-if="objs.accessory5 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申报项目涉及的产学研合作协议、研发合作协议扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory6)">
              <div v-if="objs.accessory6 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>
              申报项目列入政府资助项目、各项计划相关文件（国家级、省级、市级）扫描件
            </div>
            <div style="cursor: pointer" @click="kan(objs.accessory7)">
              <div v-if="objs.accessory7 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申报项目应用证明盖章扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory8)">
              <div v-if="objs.accessory8 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>申报项目客观评价、鉴定等相关文件扫描件</div>
            <div style="cursor: pointer" @click="kan(objs.accessory9)">
              <div v-if="objs.accessory9 != null">预览</div>
            </div>
          </div>
          <div class="box">
            <div>其他相关证明</div>
            <div style="cursor: pointer" @click="kan(objs.accessory10)">
              <div v-if="objs.accessory10 != null">预览</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="查看"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
    >
      <iframe :src="pdfUrl" width="100%" height="800px"></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Print from "vue-print-nb";
// import Vue from "vue";
// Vue.use(Print);
import { innovation_select } from "../axios/api";
import html2canvas from "html2canvas";
export default {
  props: ["objs"],
  data() {
    return {
      centerDialogVisible: false,
      is: 1,
      url: "",
      pdfUrl: "",
      id: "",
      textarea: "",
      fileList: [],
      normal: [],
      select: [],

      user_name: "",
      num: 0,
      xinxi: {},
      objss: this.objs,
      vHtml: "",
      isShows: 1,
      isShowss: 1,
      danwei: {},
      iss: 1,
      user: { name: "", age: "" },
      stu: [
        { name: "张三", age: 17 },
        { name: "里斯", age: 18 },
        { name: "王五", age: 19 },
      ],
      aaa: [],
      domain: [],
      isShow: 1,
    };
  },
  created() {
    console.log(this.objs);
    this.innovation_select();
  },
  components: {},
  mounted() {
    this.innovation_select();
  },
  methods: {
    innovation_select() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      innovation_select(params).then((res) => {
        this.domain = [];
        console.log(this.objss);
        console.log(res);
        this.objss.domain.forEach((item) => {
          res.data.data.forEach((items) => {
            if (item == items.id) {
              item = {
                id: items.id,
                name: items.name,
              };
              this.domain.push(item);
              console.log(this.domain);
            }
          });
        });
      });
    },
    checkedOne(id) {
      let idIndex = this.aaa.indexOf(id);
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        this.aaa.splice(idIndex, 1);
      } else {
        // 选中该checkbox
        this.aaa.push(id);
      }
      console.log(this.aaa);
    },
    kan(e) {
      window.open(e, "_blank");
    },
    insert: function () {
      this.stu.push(this.user);
      this.user = {};
    },
    remove: function (index) {
      this.stu.splice(index, 1);
    },

    clickXia() {
      this.isShow = 2;
    },
    shangshangbubu() {
      if (this.objs.accomplish_people.length > 0) {
        this.isShow = 4;
      } else if (this.objs.accomplish.length > 0) {
        this.isShow = 3;
      } else {
        this.isShow = 2;
      }
    },
    shangshangbu() {
      if (this.objs.accomplish.length > 0) {
        this.isShow = 3;
      } else {
        this.isShow = 2;
      }
    },
    clickXia1s() {
      if (this.objs.accomplish_people.length > 0) {
        this.isShow = 4;
      } else {
        this.isShow = 5;
      }
    },
    clickXia1s1() {
      this.isShow = 5;
    },
    clickXia1() {
      this.isShow = 1;
    },
    xiyibua() {
      if (this.objs.accomplish.length > 0) {
        this.isShow = 3;
      } else if (this.objs.accomplish_people.length > 0) {
        this.isShow = 4;
      } else {
        this.isShow = 5;
      }
    },
    fanhui() {
      this.num = 1;
    },
    fanhui1() {
      this.num = 2;
    },
    xiayibu1() {
      this.num = 2;
    },
    xiayibu2() {
      this.num = 3;
    },
    xiayibu3() {
      this.num = 4;
    },
    wancheng() {
      this.num = 3;
    },

    xiaoxiabu8() {
      this.num = 3;
    },
    xiaoxiabu() {
      this.num = 3;
    },
    shangyibu() {
      this.num = 1;
      this.isShow = 5;
    },
    dianji(e) {
      window.open(e, "_blank");
    },
    baocun() {
      this.$downloadPDF.downloadPDF(
        document.querySelector("#printTest"),
        "2023年创新成果奖"
      );
    },
    dayin() {
      console.log("打印");
      printJS({
        printable: "printTest",
        type: "html",
        repeatTableHeader: true,
        scanStyles: true,
        maxWidth: "1300px",
        style: ["*"],
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"],
      });
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.present {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  background-color: #fff;
}
.main {
  width: 100%;
  height: calc(100% - 50px);
}
.shenMain {
  width: 100%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
}
.shenMain table {
  width: 1150px;
  margin: 20px 0;
  border-collapse: collapse;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  /* width: 120px; */
  text-align: center;
  font-size: 30px;
}
caption {
  font-size: 28px;
  margin: 40px;
}
textarea {
  background: none;
  outline: none;
  border: 0px;
  width: 920px;
  height: 200px;
  font-size: 20px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 20px;
  width: 90px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 30px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.biaoge {
  width: 98%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 20px auto;
  /* justify-content: space-between; */
}
.biaoge > div:nth-child(1) {
  text-indent: 2em;
  text-align: left;
}
.zunshou {
  height: 100%;
  text-indent: 2em;
  text-align: left;
}
.biaoge > div:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 150px;
  justify-content: space-between;
}
.biaoge > div:nth-child(2) > div {
  width: 350px;
}
.xuanze input {
  border: 1px solid #999999;
}
.xuanze > div {
  margin: 10px 5px;
}
/* .xuanze {
  width: 100%;
}
.xuanze .input-checkbox {
  width: 30px;
} */
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
/* .shangchuan > div:nth-child(2) {
  display: flex;
  flex-direction: column;

  align-items: center;
} */
.clickXia1_main {
  display: flex;
  width: 100%;

  justify-content: space-around;
  align-items: center;
}
.clickXia1_main1 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.clickXia1_main > div {
  margin-left: 10px;
}
.tables {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
}
#table {
  width: 100%;
  border-collapse: collapse;
}
#table input {
  background: none;
  outline: none;
  border: 0px;
  width: 150px;
}
#table td {
  width: 80px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}
.box > div:nth-child(1) {
  width: 700px;
}
.box > div:nth-child(2) {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aaa {
  display: flex;
}
.tianjia {
  width: 100px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #409eff;
  cursor: pointer;
  color: #fff;
}
.tianjia1 {
  width: 100px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #409eff;
  cursor: pointer;
  color: #fff;
  margin-right: 20px;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.clickXia2 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
  margin-right: 10px;
}
.fengmian {
  width: 1000px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 50px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  width: 240px;
  font-size: 20px;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  border: 0px;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fengmian > div:nth-child(2) > div {
  font-size: 20px;
}
.faren {
  width: 100%;
  margin-top: 150px;
  display: flex;
  justify-content: center;
}
.faren > div {
  margin-left: 50px;
}
.kaozuo > div {
  text-align: left;
}
.kaozuo > div:nth-child(2) {
  text-indent: 2em;
}
</style>
